import React from "react";
import { alertManagementStyle } from "@aim-mf/styleguide";
import { ClusterChart } from "../components/alertComponents/alertDataSource/clusterChart";

import { PCAChart } from "../components/alertComponents/alertDataSource/PCAChart";
import { VendorScore } from "../components/alertComponents/alertDataSource/vendorScore";
import {
  alert_data_index,
  fakeBoxPlotHeader,
  fakeVendorScoreHeader,
  transactionTableHeaderMapping,
  transactionTableThirdPartyPaymentContentEmployee,
} from "./fakeData";
import { TransactionTableProfiling } from "./transactionTableProfiling";
import { BoxPlotThirdPartyPayment } from "./boxPlotThirdPartyPayment";

class VendorAnalysisDetailThirdPartyPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedCluster: [],
    };
  }
  render() {
    if (this.props.alertData.length === 0) {
      return <div />;
    }
    return (
      <div>
        <div>
          <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
            <div>
              <div style={alertManagementStyle.sectionTitleStyle}>
                Historical data
              </div>
            </div>
            <div style={{ display: "inline-flex" }}>
              <ClusterChart
                alertData={this.props.alertData}
                alertDetail={{
                  ...this.props.alertDetail,
                  alert_data_index: alert_data_index,
                }}
                height={alertManagementStyle.bubbleChartHeight}
                width={alertManagementStyle.bubbleChartWidth}
                onBubbleClick={(data) => {
                  this.setState({ focusedCluster: data });
                }}
              />
              <div style={{ width: "2.125rem" }} />
              <PCAChart
                alertDetail={{
                  ...this.props.alertDetail,
                  alert_data_index: alert_data_index,
                }}
                alertData={this.state.focusedCluster}
                height={alertManagementStyle.bubbleChartHeight}
                width={alertManagementStyle.bubbleChartWidth}
              />
            </div>
          </div>
          <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
            <div>
              <div style={alertManagementStyle.sectionTitleStyle}>
                Transactions
              </div>
            </div>
            <TransactionTableProfiling
              data={transactionTableThirdPartyPaymentContentEmployee}
              header={transactionTableHeaderMapping}
            />
          </div>

          <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
            <div>
              <div style={alertManagementStyle.sectionTitleStyle}>Device</div>
            </div>
            <VendorScore
              alertDetail={{
                ...this.props.alertDetail,
                alert_data_index: alert_data_index,
              }}
              alertData={this.state.focusedCluster}
              BoxPlotHeader={fakeVendorScoreHeader}
              additionalStyle={{
                fontSize: "0.8em",
                valueFontSize: "0.65rem",
                textHeight: "4rem",
                lineHeight: "4.5rem",
              }}
              width={"5.3125rem"}
            />
            <BoxPlotThirdPartyPayment
              alertDetail={{
                ...this.props.alertDetail,
                alert_data_index: alert_data_index,
              }}
              alertData={this.state.focusedCluster}
              BoxPlotHeader={fakeBoxPlotHeader}
              height={"31.0625rem"}
              width={"5.3125rem"}
            />
          </div>
        </div>
      </div>
    );
  }
}
export { VendorAnalysisDetailThirdPartyPayment };
