import React from "react";
import { alertManagementStyle } from "@aim-mf/styleguide";
import { ClusterChart } from "./clusterChart";
import { PCAChart } from "./PCAChart";
import { BoxPlot } from "./boxPlot";
import { TransactionTable } from "./transactionTable";
import { VendorScore } from "./vendorScore";

class VendorAnalysisDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedCluster: [],
    };
  }
  render() {
    if (this.props.alertData.length === 0) {
      return <div />;
    }
    return (
      <div>
        <div>
          <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
            <div>
              <div style={alertManagementStyle.sectionTitleStyle}>
                Historical data
              </div>
            </div>
            <div style={{ display: "inline-flex" }}>
              <ClusterChart
                alertData={this.props.alertData}
                alertDetail={this.props.alertDetail}
                height={alertManagementStyle.bubbleChartHeight}
                width={alertManagementStyle.bubbleChartWidth}
                onBubbleClick={(data) => {
                  this.setState({ focusedCluster: data });
                }}
              />
              <div style={{ width: "2.125rem" }} />
              <PCAChart
                alertDetail={this.props.alertDetail}
                alertData={this.state.focusedCluster}
                height={alertManagementStyle.bubbleChartHeight}
                width={alertManagementStyle.bubbleChartWidth}
              />
            </div>
          </div>
          <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
            <div>
              <div style={alertManagementStyle.sectionTitleStyle}>
                Transactions
              </div>
            </div>
            <TransactionTable data={this.props.data} />
          </div>

          <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
            <div>
              <div style={alertManagementStyle.sectionTitleStyle}>Device</div>
            </div>
            <VendorScore
              alertDetail={this.props.alertDetail}
              alertData={this.state.focusedCluster}
              width={"5.3125rem"}
              BoxPlotHeader={BoxPlotHeader}
            />
            <BoxPlot
              alertDetail={this.props.alertDetail}
              alertData={this.state.focusedCluster}
              height={"31.0625rem"}
              width={"5.3125rem"}
            />
          </div>
        </div>
      </div>
    );
  }
}

const BoxPlotHeader = [
  "Vendor",
  "Total\n No. of PO",
  "AVG. of PO\n every 35 days",
  "Std.Dev of\n NO, of PO every\n 35 days",
  "Percentage of\n PO created on\n non-working hours",
  "Percentage of\n PO approved on\n non-working hours",
  "Percentage of\n PO with rounded\n value",
  "PO Value\n (vendor's cluster)",
  "PO value\n (vendor)",
  "No. of Unique\n Creators",
  "No. of Unique\n Approvers",
  "Vendors with\n Similar Address",
];

export { VendorAnalysisDetail };
