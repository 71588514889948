import React from "react";
import { BoxPlotChart, alertManagementStyle } from "@aim-mf/styleguide";

class BoxPlotThirdPartyPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps() {
    let path = document.getElementsByTagName("path");
    for (let i = 0; i < path.length; i++) {
      if (path[i].getAttribute("stroke") === "rgb(0, 123, 255)") {
        path[i].setAttribute("stroke", "#A2F8F780");
      }
    }
  }

  render() {
    if (this.props.alertData.length === 0) {
      return <div />;
    }
    let dataList = this.formatChartData(this.props.alertData);
    return (
      <div>
        <div
          id={"boxplot"}
          style={{
            ...alertManagementStyle.boxPlotStyle,
            paddingLeft: "3.5625rem",
            height: "4.5rem",
            marginBottom: "0.4375rem",
          }}
        >
          {dataList.map((data) => {
            return (
              <pre
                style={{
                  ...alertManagementStyle.boxPlotTitleFont,
                  width: this.props.width,
                  height: "4rem",
                  margin: 0,
                  whiteSpace: "normal",
                  fontSize: "0.8rem",
                }}
              >
                {data.title}
              </pre>
            );
          })}
        </div>
        <div
          id={"boxplot"}
          style={{
            ...alertManagementStyle.boxPlotStyle,
            paddingLeft: "3.5625rem",
          }}
        >
          {dataList.map((data) => {
            return (
              <BoxPlotChart
                Xmin={data.upper}
                Ymin={data.lower}
                YAxisNumberFont={"0.5rem Roboto, sans-serif"}
                height={this.props.height}
                width={this.props.width}
                data={[data]}
              />
            );
          })}
        </div>
      </div>
    );
  }

  formatChartData(values) {
    let result = [];
    let ObjectKeys = this.props.BoxPlotHeader;
    let item = {};
    ObjectKeys.map((key) => {
      item.title = key;
      item.category = "";
      let alertVendor = values.filter((data) => {
        return data.Vendor === this.props.alertDetail.alert_data_index;
      })[0];
      if (alertVendor === undefined) {
        alertVendor = values[0];
      }
      key = key.replace(/\n/g, "");
      if (key === ObjectKeys[0])
        this.DetailDataFormation(
          item,
          values.map((data) => data.no_of_po),
          alertVendor.no_of_po
        );
      if (key === ObjectKeys[1])
        this.DetailDataFormation(
          item,
          values.map((data) => data.no_of_po_pertime),
          alertVendor.no_of_po_pertime
        );
      if (key === ObjectKeys[2])
        this.DetailDataFormation(
          item,
          values.map((data) => data.no_of_po_per_time_std),
          alertVendor.no_of_po_per_time_std
        );
      if (key === ObjectKeys[3])
        this.DetailDataFormation(
          item,
          values.map((data) => data.percent_created_nwh),
          alertVendor.percent_created_nwh
        );
      if (key === ObjectKeys[4])
        this.DetailDataFormation(
          item,
          values.map((data) => data.percent_approved_nwh),
          alertVendor.percent_approved_nwh
        );
      if (key === ObjectKeys[5])
        this.DetailDataFormation(
          item,
          values.map((data) => data.no_of_po_pertime),
          alertVendor.no_of_po_pertime
        );
      if (key === ObjectKeys[6])
        this.DetailDataFormation(
          item,
          values.map((data) => data.mad_cluster),
          alertVendor.mad_cluster
        );
      if (key === ObjectKeys[7])
        this.DetailDataFormation(
          item,
          values.map((data) => data.mad_vendor),
          alertVendor.mad_vendor
        );
      if (key === ObjectKeys[8])
        this.DetailDataFormation(
          item,
          values.map((data) => data.no_of_createdby),
          alertVendor.no_of_createdby
        );
      if (key === ObjectKeys[9])
        this.DetailDataFormation(
          item,
          values.map((data) => data.no_of_approvedby),
          alertVendor.no_of_approvedby
        );
      if (key === ObjectKeys[10])
        this.DetailDataFormation(
          item,
          values.map((data) => data.no_of_similar_add),
          alertVendor.no_of_similar_add
        );
      result.push(JSON.parse(JSON.stringify(item)));
    });
    return result;
  }

  DetailDataFormation(item, data, vendor_data) {
    item.lower = Math.min(...data);
    item.q1 = Quartile_25(data);
    item.median = Median(data);
    item.q3 = Quartile_75(data);
    item.upper = Math.max(...data);
    //item.mean = Mean(data);
    item.outliers = [vendor_data];
  }
}

function Mean(data) {
  return data.reduce((a, b) => a + b, 0) / data.length;
}
function Median(data) {
  return Quartile_50(data);
}

function Quartile_25(data) {
  return Quartile(data, 0.25);
}

function Quartile_50(data) {
  return Quartile(data, 0.5);
}

function Quartile_75(data) {
  return Quartile(data, 0.75);
}

function Quartile(data, q) {
  data = Array_Sort_Numbers(data);
  var pos = (data.length - 1) * q;
  var base = Math.floor(pos);
  var rest = pos - base;
  if (data[base + 1] !== undefined) {
    return data[base] + rest * (data[base + 1] - data[base]);
  } else {
    return data[base];
  }
}

function Array_Sort_Numbers(inputarray) {
  return inputarray.sort((a, b) => {
    return a - b;
  });
}

export { BoxPlotThirdPartyPayment };
