const fakeData = [
  {
    alert_number: "001",
    risk_name: "Travel and Entertainment",
    name: "Jane Smith",
    description:
      "Total claims exceeding 2 standard deviation away from the mean​",
    status: "Open",
    created_date: "02/01/2021",
    owners: ["wendyslng"],
  },
  {
    alert_number: "002",
    risk_name: "Travel and Entertainment",
    name: "Joel Van",
    description: "Unusually large single claim​",
    status: "Closed",
    created_date: "02/16/2021",
    owners: ["wendyslng"],
  },
  {
    alert_number: "003",
    risk_name: "Travel and Entertainment",
    name: "Jane Doe",
    description: "Top 20 spenders/claimant​",
    status: "Open",
    created_date: "03/25/2021",
    owners: ["wendyslng"],
  },
  {
    id: "5690794a-beef-4ab1-aa13-b5beec2376a3",
    alert_number: "004",
    risk_name: "Travel and Entertainment",
    name: "John Smith",
    description:
      "Higher level of expenditure compared to other employees in the same role​​",
    status: "In Progress",
    created_date: "04/25/2021",
    owners: ["wendyslng"],
  },
  {
    alert_number: "001",
    risk_name: "Link Analysis",
    name: "Samurai Van",
    description: "Same bank account number as Employee Samurai Car.​​​",
    status: "Open",
    created_date: "09/16/2019",
    owners: ["wendyslng"],
  },
  {
    alert_number: "002",
    risk_name: "Link Analysis",
    name: "Atlantis Star",
    description: "Same bank account number as Employee John Moon .​​​",
    status: "Closed",
    created_date: "10/25/2020",
    owners: ["wendyslng"],
  },
  {
    id: "7c680ddf-9f78-4258-93c3-0b413fc4e5b4",
    alert_number: "003",
    risk_name: "Link Analysis",
    name: "Mary Chia​",
    description: "Same bank account number as Employee Terry Johnson​​​",
    status: "Open",
    created_date: "04/25/2021",
    owners: ["wendyslng"],
  },
  {
    id: "2174042c-d345-4aca-a494-0e3b00d06a7d",
    alert_number: "001",
    risk_name: "Third-party Payments",
    name: " James Yap​",
    description: "Anomalous Vendor",
    status: "Open",
    created_date: "04/25/2021",
    owners: ["wendyslng"],
  },
  {
    alert_number: "002",
    risk_name: "Third-party Payments",
    name: "Atlantis Start",
    description: "Anomalous Vendor​​​",
    status: "Closed",
    created_date: "03/25/2020",
    owners: ["wendyslng"],
  },
  {
    alert_number: "003",
    risk_name: "Third-party Payments",
    name: "Bell People Technology​",
    description: "Anomalous Vendor​​​",
    status: "Open",
    created_date: "03/25/2020",
    owners: ["wendyslng"],
  },
  {
    alert_number: "004",
    risk_name: "Third-party Payments",
    name: "Building Provider",
    description: "Anomalous Vendor​​​",
    status: "In Review",
    created_date: "09/16/2019",
    owners: ["wendyslng"],
  },
  {
    alert_number: "005",
    risk_name: "Third-party Payments",
    name: "Samurai Van",
    description: "Anomalous Vendor​​​",
    status: "Open",
    created_date: "08/11/2018",
    owners: ["wendyslng"],
  },
];
const fakeAlertVendorDetailHeader = {
  "5690794a-beef-4ab1-aa13-b5beec2376a3": {
    employee_id: "Employee ID",
    department: "Department",
    address: "Address",
    hired_date: "Hired Date",
    email: "Email",
    country: "Country",
    postal: "Postal",
    termination_date: "Termination Date",
    cost_center: "Cost Center",
    telephone: "Telephone",
  },

  "7c680ddf-9f78-4258-93c3-0b413fc4e5b4": {
    id: "ID",
    contact: "Contact Person",
    category: "Category",
    address: "Address",
    created_date: "Created Date",
    email: "Email",
    country: "Country",
    postal: "Postal",
    approved_date: "Approved Date",
    uen: "UEN",
    telephone: "Telephone",
    bank_account: "Bank Account",
  },

  "2174042c-d345-4aca-a494-0e3b00d06a7d": {
    id: "Vendor ID",
    contact: "Contact Person",
    address: "Address",
    created_date: "Created Date",
    email: "Email",
    country: "Country",
    postal: "Postal",
    approved_date: "Approved Date",
    uen: "UEN",
    telephone: "Telephone",
  },
};
const fakeAlertVendorDetailContent = {
  "5690794a-beef-4ab1-aa13-b5beec2376a3": {
    employee_id: "E002",
    department: "Sales",
    address: "369 Telegraph Road",
    hired_date: "12/02/2018",
    email: "john@gmail.com",
    country: "Singapore",
    postal: "061369",
    termination_date: "N.A",
    cost_center: "Lk13000098",
    telephone: "99988697",
  },
  "7c680ddf-9f78-4258-93c3-0b413fc4e5b4": [
    {
      id: "V001",
      contact: "Mary Chia",
      category: "Vendor",
      address: "#22-22 222 Bedom Street 2 22",
      created_date: "12/02/2018",
      email: "555666@gmail.com",
      country: "Singapore",
      postal: "222221",
      approved_date: "12/11/2018",
      uen: "Lk13000098",
      telephone: "8889 9998",
      bank_account: "64494959",
    },
    {
      id: "E003",
      contact: "Terry Johnson",
      category: "Employee",
      address: "#22-22 222 Bedom Street 2 22",
      created_date: "24/03/2019",
      email: "555666@gmail.com",
      country: "Singapore",
      postal: "222221",
      approved_date: "17/05/2019",
      uen: "NA",
      telephone: "8889 9998",
      bank_account: "64494959",
    },
  ],
  "2174042c-d345-4aca-a494-0e3b00d06a7d": {
    id: "V001",
    contact: "James Yap",
    address: "#22-22 222 Bedom Street 2 22",
    created_date: "12/02/2018",
    email: "555666@gmail.com",
    country: "Singapore",
    postal: "222221",
    approved_date: "12/11/2018",
    uen: "Lk13000098",
    telephone: "8889 9998",
  },
};
const fakeDataBarChart = [
  { date: "Mar 2020", amount: "15", color: "#00D3ED" },
  { date: "Apr 2020", amount: "6", color: "#00D3ED" },
  { date: "May 2020", amount: "8", color: "#00D3ED" },
  { date: "Jun 2020", amount: "2", color: "#00D3ED" },
  { date: "Jul 2020", amount: "9", color: "#00D3ED" },
  { date: "Aug 2020", amount: "14", color: "#00D3ED" },
  { date: "Sep 2020", amount: "7", color: "#00D3ED" },
  { date: "Oct 2020", amount: "12", color: "#00D3ED" },
  { date: "Nov 2020", amount: "8", color: "#00D3ED" },
  { date: "Dec 2020", amount: "5", color: "#00D3ED" },
  { date: "Jan 2021", amount: "6", color: "#00D3ED" },
  { date: "Feb 2021", amount: "6", color: "#00D3ED" },
  { date: "Mar 2021", amount: "5", color: "#00D3ED" },
  { date: "Apr 2021", amount: "15", color: "#00D3ED" },
];

const claimTableHeaderMapping = {
  id: "ID​",
  type: "Claim Type",
  claim_date: "Claim Date​",
  description: "Description​",
  created_date: "Created Date​",
  created_by: "Created By​",
  approved_date: "Approved Date​",
  approved_by: "Approved By​",
  amount: "Amount​",
};

const claimTableContent = [
  {
    id: "10185​",
    type: "Business Entertainment​",
    claim_date: "01/01/2019​",
    description: "Meals with client​",
    created_date: "01/01/2019​",
    created_by: "John Smith​",
    approved_date: "05/01/2019​",
    approved_by: "Bill Chan​",
    amount: "350​",
  },
  {
    id: "10186​",
    type: "Business Entertainment​",
    claim_date: "01/01/2019​",
    description: "Meals with client​",
    created_date: "01/01/2019​",
    created_by: "John Smith​",
    approved_date: "05/01/2019​",
    approved_by: "Roy Tan​",
    amount: "450​",
  },
  {
    id: "10187​",
    type: "Business Entertainment​",
    claim_date: "01/01/2019​",
    description: "Entertainment​",
    created_date: "01/01/2019​",
    created_by: "John Smith​",
    approved_date: "05/01/2019​",
    approved_by: "Bill Chan​",
    amount: "200​",
  },
  {
    id: "10200​",
    type: "Business Entertainment​",
    claim_date: "01/02/2019​",
    description: "Entertainment​",
    created_date: "01/01/2019​",
    created_by: "John Smith​",
    approved_date: "11/02/2019​",
    approved_by: "Roy Tan​",
    amount: "450​",
  },
  {
    id: "10201​",
    type: "Business Entertainment​",
    claim_date: "01/02/2019​",
    description: "Entertainment​",
    created_date: "01/01/2019​",
    created_by: "John Smith​",
    approved_date: "11/02/2019​",
    approved_by: "Roy Tan​",
    amount: "450​",
  },
];

const transactionTableHeaderMapping = {
  id: "JE ID​",
  date: "GL Date",
  account: "GL Account",
  description: "GL Description​",
  debit: "Debit",
  credit: "Credit​",
};

const transactionTableContentVendor = [
  {
    id: "202004294",
    date: "01/01/2019",
    account: "1000123",
    description: "Bank-SG",
    debit: "",
    credit: "1,000,000",
  },
  {
    id: "202004295",
    date: "01/02/2019​",
    account: "200121",
    description: "Consulting Fees​",
    debit: "2,000,000",
    credit: "",
  },
  {
    id: "202004296",
    date: "01/02/2019​",
    account: "3000120",
    description: "Accounts Payable – Non trade​",
    debit: "",
    credit: "1,000,000",
  },
];
const transactionTableContentEmployee = [
  {
    id: "202004297",
    date: "01/01/2019​",
    account: "1000121",
    description: "Cash​",
    debit: "",
    credit: "500,000",
  },
  {
    id: "202004298",
    date: "01/02/2019​",
    account: "200123",
    description: "Service Fees​",
    debit: "500,000",
    credit: "",
  },
];
const transactionTableThirdPartyPaymentContentEmployee = [
  {
    id: "202004291",
    date: "01/01/2019​",
    account: "200121",
    description: "Consulting Fees",
    debit: "1,000,000",
    credit: "",
  },
  {
    id: "202004291",
    date: "01/02/2019​",
    account: "200122",
    description: "Commissions​",
    debit: "1,000,000",
    credit: "",
  },
  {
    id: "202004291",
    date: "01/01/2019​",
    account: "1000123",
    description: "Bank-SG",
    debit: "",
    credit: "2,000,000",
  },
  {
    id: "202004292",
    date: "01/02/2019​",
    account: "200123",
    description: "Service Fees",
    debit: "10,000,000",
    credit: "",
  },
  {
    id: "202004292",
    date: "01/02/2019​",
    account: "100123",
    description: "Bank-SG",
    debit: "",
    credit: "10,000,000",
  },
];
const alert_data_index = "100002187";

const fakeBoxPlotHeader = [
  "No. of transactions with keywords hit​",
  "No. of parties sharing common details​",
  "No. of exceptions by benford’s law​",
  "Total amount transacted​",
  "Average no. of rounded amount transactions​",
  "Frequency of rounded amount​",
  "Unusually large payment amount ​",
  "No. of duplicate transaction pairs​",
  "Payments made with unusual account pairing​",
  "No. of transactions without description",
  "Vendors with Similar Address",
];
const fakeVendorScoreHeader = [
  "Vendor",
  "No. of transactions with keywords hit​",
  "No. of parties sharing common details​",
  "No. of exceptions by benford’s law​",
  "Total amount transacted​",
  "Average no. of rounded amount transactions​",
  "Frequency of rounded amount​",
  "Unusually large payment amount ​",
  "No. of duplicate transaction pairs​",
  "Payments made with unusual account pairing​",
  "No. of transactions without description",
  "Vendors with Similar Address",
];

const networkGraphData = [
  {
    id: 0,
    type: "Vendors",
    name: "Match Ltd.",
    value: 1,
    color: "rgba(180,0,124,0.9)",
    to: [{ id: 1, label: "Phone Number" }],
  },
  {
    id: 1,
    type: "Vendors",
    name: "Mary Chia.",
    value: 1,
    color: "rgba(180,0,124,0.9)",
    to: [
      { id: 0, label: "Phone Number" },
      { id: 3, label: "Address" },
      { id: 4, label: "Bank Account" },
    ],
  },
  {
    id: 2,
    type: "Customers",
    name: "MC Ltd",
    value: 1,
    color: "rgba(68,114,196,0.9)",
    to: [{ id: 4, label: "Address" }],
  },
  {
    id: 3,
    type: "Customers",
    name: "ABC Ltd",
    value: 1,
    color: "rgba(68,114,196,0.9)",
    to: [
      { id: 1, label: "Address" },
      { id: 4, label: "Phone Number" },
    ],
  },
  {
    id: 4,
    type: "Employee",
    name: "Terry\nJohnson",
    value: 1,
    color: "rgba(112,173,71,0.9)",
    to: [
      { id: 1, label: "Bank Account" },
      { id: 2, label: "Address" },
      { id: 3, label: "Phone Number" },
      { id: 5, label: "Address" },
    ],
  },
  {
    id: 5,
    type: "Vendors",
    name: "TJ Ltd",
    value: 1,
    color: "rgba(180,0,124,0.9)",
    to: [{ id: 4, label: "Address" }],
  },
];

const triggerRiskName = [
  "Travel and Entertainment",
  "Profiling",
  "Third-party Payments",
  "Link Analysis",
];

const ERDemoTriggerRiskName = [
  "Ethical requirements",
];

export {
  alert_data_index,
  fakeData,
  fakeAlertVendorDetailHeader,
  fakeAlertVendorDetailContent,
  fakeDataBarChart,
  claimTableHeaderMapping,
  claimTableContent,
  transactionTableHeaderMapping,
  transactionTableContentVendor,
  transactionTableContentEmployee,
  transactionTableThirdPartyPaymentContentEmployee,
  fakeBoxPlotHeader,
  fakeVendorScoreHeader,
  networkGraphData,
  triggerRiskName,
  ERDemoTriggerRiskName
};
