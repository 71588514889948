import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FormatDate, alertManagementStyle } from "@aim-mf/styleguide";
import { HeaderRender } from "../../summary/HeaderRender";

class RoutineAlertTransactionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [{ field: "create_datetime", dir: "asc", data: this.props.data }],
    };
  }

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return (
      <div style={{ width: "62.5rem" }}>
        <Grid
          style={alertManagementStyle.gridStyle}
          {...this.state.dataState}
          data={this.props.data}
          sortable
          headerCell={HeaderRender}
          sort={this.state.sort}
          cellRender={defaultRowCell}
          onSortChange={(e) => {
            var sortConfig = e.sort[0];
            if (!sortConfig) {
              this.setState({
                sort: [{ data: this.props.data }],
              });
            } else if (sortConfig.field === "formatDateTime") {
              sortConfig = { ...sortConfig, data: this.props.data };
              this.setState({
                sort: [sortConfig],
              });
            }
          }}
        >
          <Column
            field="referencenumber"
            title="DOC NO."
            width={(80 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="group"
            title="DOC TYPE"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="vendor_name"
            title="VENDOR"
            width={(120 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="description"
            title="DESCRIPTION"
            headerCell={HeaderRender}
          />
          <Column
            title="Amount"
            cell={amountCell}
            width={(80 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="created_date"
            title="CREATED"
            width={(100 / 16) * rem + "px"}
            cell={defaultRowDateCell}
            headerCell={HeaderRender}
          />
          <Column
            field="created_by"
            title="CREATOR"
            width={(90 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="approved_date"
            title="APPROVED"
            width={(110 / 16) * rem + "px"}
            cell={defaultRowDateCell}
            headerCell={HeaderRender}
          />
          <Column
            field="approved_by"
            title="APPROVER"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
        </Grid>
      </div>
    );
  }
}

const defaultRowDateCell = (props) => {
  return <td>{FormatDate(props.dataItem[props.field], "DD/MM/YYYY")}</td>;
};
const defaultRowCell = (tdElement, props) => {
  return (
    <td style={{ padding: "0.3125rem", fontSize: "0.75rem" }}>
      {props.dataItem[props.field]}
    </td>
  );
};
const amountCell = (props) => {
  if (props.dataItem.group === "Payments") {
    return (
      <td style={{ fontSize: "0.75rem" }}>{props.dataItem.payment_amount}</td>
    );
  } else if (props.dataItem.group === "Purchase Orders") {
    return (
      <td style={{ fontSize: "0.75rem" }}>{props.dataItem.amount_gross}</td>
    );
  } else if (props.dataItem.group === "Goods Receipts") {
    return (
      <td style={{ fontSize: "0.75rem" }}>{props.dataItem.received_amount}</td>
    );
  } else if (props.dataItem.group === "Invoices") {
    return <td style={{ fontSize: "0.75rem" }}>{props.dataItem.amount}</td>;
  }
};

export { RoutineAlertTransactionTable };
