import React from "react";
import {
  FormatDate,
  AlertDetailSpecialCard,
} from "@aim-mf/styleguide";
import {auoSizeQA, ERDemoAlertDetailTemplate, ERDemoQuestions} from "./ERDemoData";

class ERDemoAlertDetail extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.alert);
    this.state = {
      createTask: false,
      transfer: false,
      report: false,
      declineTransfer: false,
      Confirmation: { display: false },
      alert: this.props.alert,
      taskList: this.props.taskList,
      warning: { display: false },
      viewTask: { display: false },
      answer:["","","","","",""],
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (JSON.stringify(nextProps.alert) !== JSON.stringify(this.props.alert)) {
      //console.log(nextProps.alert);
      this.setState({
        alert: nextProps.alert,
      });
    }
  }

  componentDidUpdate() {
  }

  render() {
    if (Object.keys(this.state.alert).length === 0) {
      return <div />;
    }

    let newDemoAlert = this.state.alert;
    if (this.state.alert.risk === "Travel and Entertainment") {
      newDemoAlert.description =
        "Higher level of expenditure compared to other employees in the same role​";
      newDemoAlert.alert_number = "A004";
    }
    if (this.state.alert.risk === "Profiling") {
      newDemoAlert.description =
        "Same bank account number as Employee Terry Johnson​​";
      newDemoAlert.alert_number = "A003";
    }
    if (this.state.alert.risk === "Link Analysis") {
      newDemoAlert.description =
        "Same bank account number as Employee Terry Johnson​​";
      newDemoAlert.alert_number = "A003";
    }
    if (this.state.alert.risk === "Third-party Payments") {
      newDemoAlert.description = "Anomalous Vendor";
      newDemoAlert.alert_number = "A001";
    }

    return (
          <div>
            <AlertDetailSpecialCard wrapText={true} data={ERDemoAlertDetailTemplate("Questions 1",auoSizeQA(ERDemoQuestions[0], this.state.answer[0], 0, this.answerChange))}/>
            <AlertDetailSpecialCard wrapText={true} data={ERDemoAlertDetailTemplate("Questions 2",auoSizeQA(ERDemoQuestions[1], this.state.answer[1], 1, this.answerChange))}/>
            <AlertDetailSpecialCard wrapText={true} data={ERDemoAlertDetailTemplate("Questions 3",auoSizeQA(ERDemoQuestions[2], this.state.answer[2], 2, this.answerChange))}/>
            <AlertDetailSpecialCard wrapText={true} data={ERDemoAlertDetailTemplate("Questions 4",auoSizeQA(ERDemoQuestions[3], this.state.answer[3], 3, this.answerChange))}/>
            <AlertDetailSpecialCard wrapText={true} data={ERDemoAlertDetailTemplate("Questions 5",auoSizeQA(ERDemoQuestions[4], this.state.answer[4], 4, this.answerChange))}/>
            <AlertDetailSpecialCard wrapText={true} data={ERDemoAlertDetailTemplate("Questions 6",auoSizeQA(ERDemoQuestions[5], this.state.answer[5], 5, this.answerChange))}/>
          </div>
    );
  }

  answerChange=(index, value)=>{
    let answer = this.state.answer
    answer[index]=value
    this.setState({answer:answer})
  }

  handleViewTaskSave = (task) => {
    //console.log(this.mappingFrontendToBackend(task));
    this.props.handleTaskUpdate(task.id, this.mappingFrontendToBackend(task));
    this.setState({ viewTask: { display: false, Detail: {} } });
  };

  handleTaskClose = (taskID) => {
    this.setState({ viewTask: { display: false, Detail: {} } });
    this.props.handleTaskClose(taskID);
  };
  handleTaskOpen = (taskID) => {
    this.setState({ viewTask: { display: false, Detail: {} } });
    this.props.handleTaskOpen(taskID);
  };

  mappingFrontendToBackend = (frontendData) => {
    //console.log(frontendData);
    let comments = [];
    frontendData.comments.forEach((comment) => {
      //console.log(FormatDate(comment.dateTime, "YYYY-MM-DD HH:mm:ss"));
      comments.push({
        content: comment.commentText,
        username: comment.userName,
        time: FormatDate(comment.dateTime, "YYYY-MM-DD HH:mm:ss"),
      });
    });
    let actions = [];
    frontendData.actions.forEach((action) => {
      //console.log(action);
      actions.push({
        action_id: action.actionID,
        action_type: action.actionType,
        action_subtype: action.actionContent[0].type,
        action_question:
          action.actionContent[0].description ||
          action.actionContent[0].question,
        action_answer: action.actionContent[0].answer,
        action_options: action.actionContent[0].options,
        action_choices: action.actionContent[0].selected
          ? Array.isArray(action.actionContent[0].selected)
            ? action.actionContent[0].selected
            : [action.actionContent[0].selected]
          : [],
        action_new_attachment: action.newAttchments,
        action_remove_attachment_ID:
          action.actionContent[0].removeActionAttachmentID,
      });
    });
    let taskContent = {
      due_date: frontendData.dueDate || FormatDate(new Date(), "YYYY-MM-DD"),
      title: frontendData.title,
      description: frontendData.description,
      actions: actions,
      task_comment: comments,
      task_from: this.props.currentUser.username,
      task_new_attachments: frontendData.newAttchments,
      task_remove_attachment_ID: frontendData.removeTaskAttachmentID,
      //eventDeleteAction: this.state.eventDeleteAction
    };

    return taskContent;
  };

  handleDeclineTransferPopup = () => {
    this.setState({ declineTransfer: true });
  };

  handleUsefulChange = (value) => {
    let alert = this.state.alert;
    alert.is_useful = value;
    this.setState({ alert: alert });
  };


  handleTransferAlert = () => {
    this.setState({ transfer: true, report: false, createTask: false });
  };

  handleRationalChange = (value) => {
    let alert = this.state.alert;
    alert.rational = value;
    this.setState({ alert: alert });
  };

}

export { ERDemoAlertDetail };
