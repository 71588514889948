import React from "react";
import { alertManagementStyle } from "@aim-mf/styleguide";

class AlertSummaryDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          ...alertManagementStyle.descriptionWrapperStyle,
          ...this.props.extraStyle,
        }}
      >
        <div style={alertManagementStyle.titleFontStyle}>
          {this.props.title}
        </div>
        <div style={alertManagementStyle.separatorFontStyle}>Description</div>
        <div style={alertManagementStyle.descriptionFontStyle}>
          {this.props.descriprtion}
        </div>
      </div>
    );
  }
}

export { AlertSummaryDescription };
