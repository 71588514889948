import React from "react";
import {
  mapping,
  AutoSizeTextArea,
  ButtonSolid,
  alertManagementStyle,
} from "@aim-mf/styleguide";

class DeclineTransferPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
    };
  }

  render() {
    return (
      <div style={alertManagementStyle.RiskCreationFormWrapStyle}>
        <div style={alertManagementStyle.RiskCreationFormStyle}>
          <div style={alertManagementStyle.formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Decline an Alert Transfer
            </div>
            <div style={{ height: "3.125rem" }} />
            <div style={mapping["forms/label/1-default"]}>Description</div>
            <AutoSizeTextArea
              fieldstyle={{ ...alertManagementStyle.popupInputFieldStyle }}
              minRows={3}
              value={this.state.msg}
              CustomizedOnChange={(value) => {
                // eslint-disable-next-line no-console
                this.setState({ msg: value });
              }}
            />
            <div style={{ height: "2.5rem" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={alertManagementStyle.popupCancelButtonWidth}
                height={alertManagementStyle.popupButtonHeight}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Send Transfer Request"}
                color={"Primary"}
                width={alertManagementStyle.popupSubmitButtonWidth}
                height={alertManagementStyle.popupButtonHeight}
                clickEvent={() => {
                  this.props.submitFunction(this.state.msg);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { DeclineTransferPopup };
