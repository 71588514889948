import React from "react";
import {
  mapping,
  NameCard,
  NonValidationInput,
  DropDownListButton,
  riskEditStyle,
  alertManagementStyle,
} from "@aim-mf/styleguide";
import { AlertDataChart } from "../../../summary/AlertDataChart";
import { AlertSummaryDescription } from "../../../summary/AlertSummaryDescription";

class AlertKRIDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  render() {
    let chartValue = this.props.object.value;
    // console.log(this.props.object);
    return (
      <div style={alertManagementStyle.KRIWrapperStyle}>
        <div style={alertManagementStyle.KRITitleStyle}>Alert Detail</div>

        <div style={alertManagementStyle.detailStyleWrapper}>
          <div>
            <AlertSummaryDescription
              extraStyle={{ height: "fit-content", marginBottom: "2.5rem" }}
              descriprtion={this.props.object.description}
              title={this.props.title}
            />
          </div>
          <div
            style={{
              margin: "auto",
              width: "fit-content",
              marginBottom: "2.5rem",
            }}
          >
            <AlertDataChart
              object={this.props.object}
              value={chartValue}
              title={this.props.title}
            />
          </div>

          <div>
            <div style={riskEditStyle.sectionTitleStyle}>Detail</div>
          </div>

          {/*line one*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>KRI Number</div>
              <div style={alertManagementStyle.KRIContentStyle}>
                {this.props.object.number}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Frequency</div>
              <div style={alertManagementStyle.KRIContentStyle}>
                {this.props.object.frequency}
              </div>
            </div>
            <div style={{ width: "22.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Type</div>
              <div style={alertManagementStyle.KRIContentStyle}>
                {this.props.object.KRI_type}
              </div>
            </div>
          </div>

          {/*line two*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "37.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Owner</div>
              <div>
                <div style={{ paddingRight: "2rem" }}>
                  <div style={{ paddingTop: "1.25rem" }}>
                    {this.props.object.owner.length !== 0 &&
                      this.props.object.owner.map((owner) => {
                        return (
                          <NameCard
                            key={owner.display_name + owner.title}
                            initial={owner.display_name
                              .split(" ")
                              .map((a) => {
                                return a[0].toUpperCase();
                              })
                              .join("")}
                            name={owner.display_name}
                            title={owner.title}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Threshold</div>
              <div>
                <div
                  style={{
                    display: "inline-flex",
                    paddingTop: "1.25rem",
                    marginLeft: "0.1875rem",
                  }}
                >
                  <DropDownListButton
                    value={this.props.object.threshold_unit}
                    data={["%", ".00"]}
                    DropDownButtonStyle={
                      alertManagementStyle.riskThresholdTypeDropDownButtonStyle
                    }
                    disabled={true}
                  />
                  <ThresholdLowerInputView
                    safeZone={this.props.object.safe_zone}
                    unit={this.props.object.threshold_unit}
                    lower={this.props.object.lower_threshold}
                  />

                  <ThresholdMiddleRange
                    unit={this.props.object.currentUnit}
                    lower={this.props.object.lower_threshold}
                    upper={this.props.object.upper_threshold}
                  />
                  <ThresholdUpperInputView
                    safeZone={this.props.object.safe_zone}
                    unit={this.props.object.currentUnit}
                    upper={this.props.object.upper_threshold}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ThresholdMiddleRange = (props) => {
  return (
    <div style={riskEditStyle.thresholdMiddleStyle}>
      {props.lower}
      {props.unit === "%" ? "%" : ""}-{props.upper}
      {props.unit === "%" ? "%" : ""}
    </div>
  );
};

const ThresholdLowerInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={riskEditStyle.ThresholdSymbolStyle}> {"<"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "low"
            ? riskEditStyle.ThresholdSafeInputFieldStyle
            : riskEditStyle.ThresholdDangerInputFieldStyle
        }
        value={props.lower}
        disabled={true}
      />
      {props.unit === "%" && (
        <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
      )}
    </div>
  );
};
const ThresholdUpperInputView = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <span style={riskEditStyle.ThresholdSymbolStyle}> {">"} </span>
      <NonValidationInput
        fieldstyle={
          props.safeZone === "high"
            ? riskEditStyle.ThresholdSafeInputFieldStyle
            : riskEditStyle.ThresholdDangerInputFieldStyle
        }
        value={props.upper}
        disabled={true}
      />
      {props.unit === "%" && (
        <span style={riskEditStyle.ThresholdUnitStyle}> {"%"} </span>
      )}
    </div>
  );
};

export { AlertKRIDetail };
