import React from "react";
import {
  ButtonSolid,
  IconButtonSolid,
  IconSVG,
  alertManagementStyle,
} from "@aim-mf/styleguide";

class TaskReference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  mappingBackendToFrontEndData(task, username) {
    let frontendTaskData = {
      id: task.id,
      category: task.to.includes(username)
        ? "inbox"
        : task.task_from === username
        ? "pending"
        : "error",
      type: task.alert_id ? "alert" : "task",
      title: task.title,
      description: task.description,
      date: "08/03/2020",
      dueDate: task.due_date,
      status: task.status,
      from: task.task_from,
      to: task.to,
      commentDraft: "",
      comments: [],
      actions: [],
      attachments: task.attachments,
      alert_id: task.alert_id,
      removeTaskAttachmentID: [],
    };
    task.actions.forEach((action) => {
      frontendTaskData.actions.push({
        actionID: action.action_id,
        actionType: action.action_type,
        actionContent: [
          {
            type: action.action_subtype,
            question: action.action_question,
            answer: action.action_answer,
            options: action.action_options,
            selected: action.action_choices,
            attachments: action.attachments,
            removeActionAttachmentID: [],
          },
        ],
      });
    });
    task.task_comment.forEach((comment) => {
      frontendTaskData.comments.push({
        commentText: comment.content,
        dateTime: comment.time,
        userDisplay: this.props.userList[comment.username],
        userName: comment.username,
      });
    });
    return frontendTaskData;
  }
  render() {
    if (!this.props.task || this.props.task.length === 0) {
      return (
        <div style={alertManagementStyle.taskWrapperStyle}>
          <div style={alertManagementStyle.taskTitleStyle}>Task Reference</div>
          <div style={alertManagementStyle.createTaskButtonWrapperStyle}>
            <IconButtonSolid
              name={"Create Task"}
              width={alertManagementStyle.iconButtonWidth}
              height={alertManagementStyle.iconButtonHeight}
              iconPos={"Left"}
              icon={<IconSVG name={"speaker"} color={"white"} size={0.6} />}
              size={"Small"}
              clickEvent={this.props.handleCreateTask}
              disabled={!this.props.editable}
            />
          </div>
        </div>
      );
    }
    let taskList = [];
    this.props.task.forEach((taskItem) => {
      taskList.push(
        this.mappingBackendToFrontEndData(
          taskItem,
          this.props.currentUser.username
        )
      );
    });
    return (
      <div style={alertManagementStyle.taskWrapperStyle}>
        <div style={alertManagementStyle.taskTitleStyle}>Task Reference</div>
        <div style={alertManagementStyle.createTaskButtonWrapperStyle}>
          <IconButtonSolid
            name={"Create Task"}
            width={alertManagementStyle.iconButtonWidth}
            height={alertManagementStyle.iconButtonHeight}
            iconPos={"Left"}
            icon={<IconSVG name={"speaker"} color={"white"} size={0.6} />}
            size={"Small"}
            clickEvent={this.props.handleCreateTask}
            disabled={!this.props.editable}
          />
        </div>
        <div
          style={{ maxHeight: "30rem", overflowY: "scroll" }}
          className={"scrollbar"}
        >
          {taskList.map((task, index) => {
            //console.log(task);
            return (
              <div style={alertManagementStyle.taskStyleWrapper}>
                <div style={alertManagementStyle.createTaskButtonWrapperStyle}>
                  <ButtonSolid
                    name={"View Detail"}
                    width={alertManagementStyle.iconButtonWidth}
                    height={"1.5rem"}
                    color={"Secondary"}
                    clickEvent={() => {
                      this.props.handleViewTask(task);
                    }}
                  />
                </div>
                {/*task description*/}
                <div style={alertManagementStyle.sectionTitleStyle}>
                  Description
                </div>
                <div style={alertManagementStyle.taskContentStyle}>
                  {task.description}
                </div>
                <div style={{ height: "1.75rem" }} />

                <div style={{ display: "inline-flex" }}>
                  <div style={{ width: "12rem", marginRight: "0.8125rem" }}>
                    <div style={alertManagementStyle.sectionTitleStyle}>
                      Assigned To
                    </div>
                    <div style={alertManagementStyle.taskShortContentStyle}>
                      {
                        this.props.userList.filter((user) => {
                          return user.username === task.to[0];
                        })[0].display_name
                      }
                    </div>
                  </div>
                  <div style={{ width: "9.0625rem", marginRight: "0.8125rem" }}>
                    <div style={alertManagementStyle.sectionTitleStyle}>
                      Due Date
                    </div>
                    <div style={alertManagementStyle.taskShortContentStyle}>
                      {task.dueDate}
                    </div>
                  </div>
                  <div style={{ width: "9.0625rem", marginRight: "0.8125rem" }}>
                    <div style={alertManagementStyle.sectionTitleStyle}>
                      Status
                    </div>
                    <div style={alertManagementStyle.taskShortContentStyle}>
                      {task.status}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export { TaskReference };
