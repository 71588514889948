import React from "react";
import { mapping, FormatDate, alertManagementStyle } from "@aim-mf/styleguide";

class AlertVendorDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  render() {
    if (!this.props.alertDetail) {
      return <div />;
    }
    return (
      <div>
        <div style={alertManagementStyle.taskTitleStyle}>Alert Detail</div>

        <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
          <div>
            <div style={alertManagementStyle.sectionTitleStyle}>
              {this.props.alertDetail.name}
            </div>
          </div>

          {/*line one*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>Vendor ID</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {this.props.alertDetail.vendor_id}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Contact Person</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {this.props.alertDetail.created_by}
              </div>
            </div>
            <div style={{ width: "22.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Address</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {this.props.alertDetail.address}
              </div>
            </div>
          </div>

          {/*line two*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>Created Date</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {FormatDate(
                  this.props.alertDetail.created_datetime,
                  "DD/MM/YYYY"
                )}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Email</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {this.props.alertDetail.email}
              </div>
            </div>
            <div style={{ width: "9.375rem", marginRight: "3.75rem" }}>
              <div style={mapping["forms/label/1-default"]}>Country</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {this.props.alertDetail.country}
              </div>
            </div>
            <div style={{ width: "9.375rem" }}>
              <div style={mapping["forms/label/1-default"]}>Postal</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {this.props.alertDetail.postal_code}
              </div>
            </div>
          </div>

          {/*line three*/}
          <div style={{ display: "inline-flex", paddingBottom: "1.875rem" }}>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>UEN</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {this.props.alertDetail.company_code}
              </div>
            </div>
            <div style={{ width: "22.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Telephone</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {this.props.alertDetail.telephone}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { AlertVendorDetail };
