import React from "react";
import { alertManagementStyle } from "@aim-mf/styleguide";
import { RoutineAlertTransactionTable } from "./routineAlertTransactionTable";
import { RoutineAlertHorizontalBarChart } from "./routineAlertHorizontalBarChart";

class RoutineAlertDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedCluster: [],
    };
  }
  render() {
    if (this.props.alertData.length === 0) {
      return <div />;
    }
    return (
      <div>
        <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
          <div>
            <div style={alertManagementStyle.sectionTitleStyle}>
              Total Amount
            </div>
          </div>
          <RoutineAlertHorizontalBarChart
            height={"15.625rem"}
            width={"100%"}
            data={this.props.alertData.related_transaction}
          />
        </div>
        <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
          <div>
            <div style={alertManagementStyle.sectionTitleStyle}>
              Transactions
            </div>
          </div>
          <RoutineAlertTransactionTable
            data={this.props.alertData.related_transaction}
          />
        </div>
        ]
      </div>
    );
  }
}
export { RoutineAlertDetail };
