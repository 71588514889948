import React from "react";
import { alertManagementStyle, AlertBarChart } from "@aim-mf/styleguide";
import { ClusterChart } from "../components/alertComponents/alertDataSource/clusterChart";
import { PCAChart } from "../components/alertComponents/alertDataSource/PCAChart";
import { BoxPlot } from "../components/alertComponents/alertDataSource/boxPlot";
import { TransactionTable } from "../components/alertComponents/alertDataSource/transactionTable";
import { VendorScore } from "../components/alertComponents/alertDataSource/vendorScore";
import {
  fakeDataBarChart,
  transactionTableContentEmployee,
  transactionTableContentVendor,
  transactionTableHeaderMapping,
} from "./fakeData";
import { ClaimTable } from "./claimTable";
import { TransactionTableProfiling } from "./transactionTableProfiling";

class VendorAnalysisDetailProfiling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedCluster: [],
    };
  }
  render() {
    if (this.props.alertData.length === 0) {
      return <div />;
    }
    let fakScatterPlotData = this.props.alertData
      .filter((a) => {
        return a.vendor_cluster === 3;
      })
      .map((a) => {
        a.PCA1 = 1 + Math.abs(a.PCA1);
        a.PCA2 = 1 + Math.abs(a.PCA2);
        return a;
      });

    let fakeScatterPlotHighlight = {
      alert_data_index: "100002006",
    };

    return (
      <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
        <div style={alertManagementStyle.sectionTitleStyle}>
          Transactions with Vendor Mary Chia
        </div>
        <TransactionTableProfiling
          data={transactionTableContentVendor}
          header={transactionTableHeaderMapping}
        />
        <div style={{ height: "2rem" }} />
        <div style={alertManagementStyle.sectionTitleStyle}>
          Transactions with Employee Terry Johnson
        </div>
        <TransactionTableProfiling
          data={transactionTableContentEmployee}
          header={transactionTableHeaderMapping}
        />
      </div>
    );
  }
}
export { VendorAnalysisDetailProfiling };
