import React from "react";
import {
  mapping,
  FormatDate,
  NameCard,
  alertManagementStyle,
} from "@aim-mf/styleguide";

class AlertIAEDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  render() {
    if (!this.props.alertedRiskDetail) {
      return <div />;
    }
    let alertedControl = this.props.alertedRiskDetail.controls.filter(
      (control) => {
        return control.id === this.props.alertDetail.alerted_object_id;
      }
    )[0];
    return (
      <div>
        <div style={alertManagementStyle.taskTitleStyle}>Alert Detail</div>

        <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
          <div>
            <div style={alertManagementStyle.sectionTitleStyle}>
              {this.props.alertedRiskDetail.riskNumber +
                " " +
                this.props.alertedRiskDetail.name}
            </div>
          </div>

          {/*line one*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>Control Number</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {alertedControl.number}
              </div>
            </div>
            <div
              style={{
                width: "47.5rem",
                height: "fit-content",
                marginRight: "2.5rem",
              }}
            >
              <div style={mapping["forms/label/1-default"]}>
                Control Description
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {alertedControl.description}
              </div>
            </div>
          </div>

          {/*line two*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>Created Date</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {FormatDate(
                  this.props.alertDetail.created_datetime,
                  "DD/MM/YYYY"
                )}
              </div>
            </div>
            <div style={{ width: "13.75rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                First Line of Defense
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {alertedControl.management_assurance_strength}
              </div>
            </div>
            <div style={{ width: "13.75rem", marginRight: "3.75rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                Second Line of Defense
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {alertedControl.ia_assurance_strength}
              </div>
            </div>
            <div style={{ width: "13.75rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                Third Line of Defense
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {alertedControl.other_assurance_strength}
              </div>
            </div>
          </div>

          {/*line three*/}
          <div style={{ display: "inline-flex", paddingBottom: "1.875rem" }}>
            {/*<div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>*/}
            {/*  <div style={mapping["forms/label/1-default"]}>ApprovedDate</div>*/}
            {/*  <div style={contentStyle}>*/}
            {/*    {this.props.alertDetail.approved_date}*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div style={{ width: "30rem" }}>
              <div style={mapping["forms/label/1-default"]}>Control Owner</div>
              <div style={{ display: "inline-flex", marginTop: "0.625rem" }}>
                {alertedControl.owner.map((username, index) => {
                  let owner = this.props.userList.filter((user) => {
                    return user.username === username;
                  })[0];
                  if (Object.keys(owner).length === 0) {
                    return <div />;
                  } else {
                    return (
                      <div style={{ marginRight: "0.625rem" }}>
                        <NameCard
                          key={owner.display_name + owner.title}
                          initial={owner.display_name
                            .split(" ")
                            .map((a) => {
                              return a[0].toUpperCase();
                            })
                            .join("")}
                          name={owner.display_name}
                          title={owner.title}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <div style={{ width: "30rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>Risk Owner</div>
              <div style={{ display: "inline-flex", marginTop: "0.625rem" }}>
                {this.props.alertedRiskDetail.owner.map((username, index) => {
                  let owner = this.props.userList.filter((user) => {
                    return user.username === username;
                  })[0];
                  if (Object.keys(owner).length === 0) {
                    return <div />;
                  } else {
                    return (
                      <div style={{ marginRight: "0.625rem" }}>
                        <NameCard
                          key={owner.display_name + owner.title}
                          initial={owner.display_name
                            .split(" ")
                            .map((a) => {
                              return a[0].toUpperCase();
                            })
                            .join("")}
                          name={owner.display_name}
                          title={owner.title}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { AlertIAEDetail };
