import React from "react";
import {
  mapping,
  AutoSizeTextArea,
  MultiSelector,
  ButtonSolid,
  alertManagementStyle,
} from "@aim-mf/styleguide";

class TransferAlertPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transferee: "",
      transferComment: "",
      createTask: false,
      Confirmation: { display: false },
    };
  }

  render() {
    return (
      <div style={alertManagementStyle.RiskCreationFormWrapStyle}>
        <div style={alertManagementStyle.RiskCreationFormStyle}>
          <div style={alertManagementStyle.formBgStyle}>
            <div style={mapping["heading/h5/lightleft"]}>
              Alert Transfer Request
            </div>
            <div style={{ height: "3.125rem" }} />
            <div style={mapping["forms/label/1-default"]}>Description</div>
            <AutoSizeTextArea
              fieldstyle={{ ...alertManagementStyle.popupInputFieldStyle }}
              minRows={3}
              value={this.state.transferComment}
              CustomizedOnChange={(value) => {
                // eslint-disable-next-line no-console
                //console.log(value)
                this.setState({ transferComment: value });
              }}
            />
            <div style={{ height: "2.5rem" }} />
            <MultiSelector
              title={"Directory"}
              userList={this.props.userList}
              value={[]}
              CustomOnChange={(value) => {
                // eslint-disable-next-line no-console
                this.setState({ transferee: value.value });
              }}
            />
            <div style={{ height: "4rem" }} />
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ButtonSolid
                name={"cancel"}
                color={"Secondary"}
                width={alertManagementStyle.popupCancelButtonWidth}
                height={alertManagementStyle.popupButtonHeight}
                clickEvent={this.props.cancelFunction}
              />
              <ButtonSolid
                name={"Send Transfer Request"}
                color={"Primary"}
                width={alertManagementStyle.popupSubmitButtonWidth}
                height={alertManagementStyle.popupButtonHeight}
                clickEvent={() => {
                  this.props.submitFunction(
                    this.state.transferee,
                    this.state.transferComment
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { TransferAlertPopup };
