import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FormatDate, alertManagementStyle } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";
import { HeaderRender } from "./HeaderRender";

class IAEAlertsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [{ field: "create_datetime", dir: "asc", data: this.props.data }],
    };
  }
  rowClick = (event) => {
    let last = this.lastSelectedIndex;
    const result = [...this.state.result];
    const current = result.findIndex((dataItem) => dataItem === event.dataItem);
    result.forEach((item) => (item.selected = false));
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      result[i].selected = select;
    }
    this.setState({ result });
  };

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    let chartData = JSON.parse(JSON.stringify(this.props.data));
    if (this.props.riskList.length !== 0) {
      chartData.map((alert) => {
        let risk = this.props.riskList.filter((risk) => {
          return risk.id === alert.risk_id;
        })[0];
        if (risk) {
          let controls = risk.controls;
          let alerted_control = controls.filter((control) => {
            return control.id === alert.alerted_object_id;
          })[0];
          alert.control_description = alerted_control.description;
          alert.management_assurance_strength =
            alerted_control.management_assurance_strength;
          alert.ia_assurance_strength = alerted_control.ia_assurance_strength;
          alert.other_assurance_strength =
            alerted_control.other_assurance_strength;
        }
      });
    }

    return (
      <div style={alertManagementStyle.tableWrapper}>
        <Grid
          style={alertManagementStyle.gridStyle}
          {...this.state.dataState}
          data={chartData}
          sortable
          cellRender={defaultRowCell}
          onRowClick={this.rowClick}
          sort={this.state.sort}
          rowRender={this.rowRender}
          onSortChange={(e) => {
            var sortConfig = e.sort[0];
            if (!sortConfig) {
              this.setState({
                sort: [{ data: chartData }],
              });
            } else if (sortConfig.field === "formatDateTime") {
              sortConfig = { ...sortConfig, data: chartData };
              this.setState({
                sort: [sortConfig],
              });
            }
          }}
        >
          <Column
            field="alert_number"
            title="ID"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
            cell={(props) => {
              return (
                <td className="table-risk-id">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div
                    className="table-risk-id div_hover"
                    onClick={() => {
                      navigateToUrl(
                        window.location.origin +
                          "/alert-management/" +
                          props.dataItem.id +
                          "/"
                      );
                      this.props.reloadAll();
                    }}
                  >
                    {props.dataItem["parentRisk_id"] ? "⤷" : " "}
                    {props.dataItem[props.field]}
                  </div>
                </td>
              );
            }}
          />
          <Column
            field="risk"
            title="Risk Name"
            headerCell={HeaderRender}
            width={(120 / 16) * rem + "px"}
          />
          <Column
            field="control_description"
            title="Control Description"
            width="300px"
            headerCell={HeaderRender}
          />
          <Column
            field="management_assurance_strength"
            title="First Line of Defense"
            headerCell={HeaderRender}
          />
          <Column
            field="ia_assurance_strength"
            title="Second Line of Defense"
            headerCell={HeaderRender}
          />
          <Column
            field="other_assurance_strength"
            title="Third Line of Defense"
            headerCell={HeaderRender}
          />

          <Column
            field="status"
            title="Status"
            width={(80 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="create_datetime"
            title="Date Created"
            width={(140 / 16) * rem + "px"}
            headerCell={HeaderRender}
            cell={(props) => {
              if (props.dataItem[props.field]) {
                return (
                  <td>
                    <div>
                      {FormatDate(props.dataItem[props.field], "DD/MM/YYYY")}
                    </div>
                  </td>
                );
              }
            }}
          />
          <Column
            field="owners"
            title="Alert Owner"
            width={(90 / 16) * rem + "px"}
            headerCell={HeaderRender}
            cell={(props) => {
              if (props.dataItem[props.field]) {
                return (
                  <td>
                    {props.dataItem[props.field].map((owner) => {
                      return (
                        <div>{owner}</div>
                        // <Pill
                        //   pillStyle={{
                        //     display: "inline-flex",
                        //     marginRight: "0.3rem",
                        //   }}
                        //   content={owner}
                        //   color={"rgba(124,199,255,0.2)"}
                        // />
                      );
                    })}
                  </td>
                );
              } else {
                return <td />;
              }
            }}
          />
        </Grid>
      </div>
    );
  }
}

const defaultRowCell = (tdElement, props) => {
  return (
    <td
      style={{
        padding: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "12px",
      }}
    >
      {props.dataItem[props.field]}
    </td>
  );
};

export { IAEAlertsTable };
