import React from "react";
import { alertManagementStyle, AlertBarChart } from "@aim-mf/styleguide";
import { ClusterChart } from "../components/alertComponents/alertDataSource/clusterChart";
import { PCAChart } from "../components/alertComponents/alertDataSource/PCAChart";
import { BoxPlot } from "../components/alertComponents/alertDataSource/boxPlot";
import { TransactionTable } from "../components/alertComponents/alertDataSource/transactionTable";
import { VendorScore } from "../components/alertComponents/alertDataSource/vendorScore";
import { fakeDataBarChart } from "./fakeData";
import { ClaimTable } from "./claimTable";

class VendorAnalysisDetailTE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedCluster: [],
    };
  }
  render() {
    if (this.props.alertData.length === 0) {
      return <div />;
    }
    let fakScatterPlotData = this.props.alertData
      .filter((a) => {
        return a.vendor_cluster === 3;
      })
      .map((a) => {
        a.PCA1 = 1 + Math.abs(a.PCA1);
        a.PCA2 = 1 + Math.abs(a.PCA2);
        return a;
      });

    let fakeScatterPlotHighlight = {
      alert_data_index: "100002006",
    };

    return (
      <div>
        <div>
          <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
            <div>
              <div style={alertManagementStyle.sectionTitleStyle}>
                Historical data
              </div>
            </div>
            <div style={{ display: "inline-flex" }}>
              <AlertBarChart
                XAxisName={"Date"}
                YAxisName={"Total Amount"}
                max={20}
                height={alertManagementStyle.bubbleChartHeight}
                width={alertManagementStyle.bubbleChartWidth}
                data={fakeDataBarChart}
                gap={0.3}
              />
              <div style={{ width: "2.125rem" }} />
              <PCAChart
                XAxisName={"Total no. of transactions"}
                YAxisName={"Total Amount"}
                alertDetail={fakeScatterPlotHighlight}
                alertData={fakScatterPlotData}
                height={alertManagementStyle.bubbleChartHeight}
                width={alertManagementStyle.bubbleChartWidth}
              />
            </div>
          </div>
          <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
            <div>
              <div style={alertManagementStyle.sectionTitleStyle}>Claims</div>
            </div>
            <ClaimTable />
          </div>
        </div>
      </div>
    );
  }
}
export { VendorAnalysisDetailTE };
