import React from "react";
import { mapping, BubbleChart } from "@aim-mf/styleguide";

class ClusterChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedCluster: [],
      sortedData: [],
    };
  }

  componentWillMount() {
    let sortedData = this.sortDataByCluster(this.props.alertData);
    this.setState({ sortedData: sortedData });
    sortedData.map((value) => {
      value.map((vendor) => {
        if (vendor.Vendor === this.props.alertDetail.alert_data_index) {
          this.props.onBubbleClick(value);
        }
      });
    });
  }

  sortDataByCluster(data) {
    // get the max number of clusters so that can determine the array size
    let cluster = data.map((vendor) => vendor.vendor_cluster);
    let no_of_cluster = Math.max(...cluster) + 1;
    // declare the array with size of max cluster number
    let result = [...Array(no_of_cluster)].map((x) => []);
    // sort data into different array index according to the cluster number
    data.map((vendor) => {
      result[vendor.vendor_cluster].push(vendor);
    });

    return result;
  }

  findMostFrequentKeyword(value, number) {
    // get all keywords in this cluster in a hashmap
    let keywordsHashMap = {};
    let keyword = value.map((vendor) => vendor.keywords);
    keyword.map((keyword) => {
      let keywordList = keyword.split(",");
      keywordList.map((word) => {
        if (!keywordsHashMap[word]) {
          keywordsHashMap[word] = 1;
        } else {
          keywordsHashMap[word] = keywordsHashMap[word] + 1;
        }
      });
    });
    // sort the keys according to the appear times.
    let mostFrequentKeyword = Object.keys(keywordsHashMap).sort(
      (a, b) => keywordsHashMap[b] - keywordsHashMap[a]
    );
    // take the most frequent appearing keyword
    return mostFrequentKeyword.splice(0, number);
  }

  formatChartData(values) {
    let result = [];
    // sort data into smaller list according to cluster number
    let sortedData = this.sortDataByCluster(values);
    sortedData.map((value) => {
      let item = {};
      // number of vendors in the cluster
      item["x"] = value.length;
      // total po amount in million
      item["y"] =
        value
          .map((vendor) => vendor.total_PO_amount)
          .reduce((a, b) => a + b, 0) / 1000000;
      // total po number
      item["size"] = value
        .map((vendor) => vendor.no_of_po)
        .reduce((a, b) => a + b, 0);
      item["color"] = "rgba(255,255,255,0.17)";
      item["category"] = this.findMostFrequentKeyword(value, 2);
      value.map((vendor) => {
        if (vendor.Vendor === this.props.alertDetail.alert_data_index) {
          item["color"] = "#A2F8F7";
        }
      });
      result.push(item);
    });
    return result;
  }

  render() {
    if (this.props.alertData.length === 0) {
      return <div />;
    }
    return (
      <BubbleChart
        XAxisName={"No. of Vendors (In Cluster)"}
        YAxisName={"Amount of Payment (Millions)"}
        height={this.props.height}
        width={this.props.width}
        data={this.formatChartData(this.props.alertData)}
        onBubbleClick={(event) => {
          let dataIndex = event.series.data.indexOf(event.dataItem);
          this.props.onBubbleClick(this.state.sortedData[dataIndex]);
        }}
      />
    );
  }
}

export { ClusterChart };
