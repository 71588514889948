import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FormatDate } from "@aim-mf/styleguide";
import { HeaderRender } from "../../summary/HeaderRender";

class TransactionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [{ field: "create_datetime", dir: "asc", data: this.props.data }],
    };
  }

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return (
      <div style={{ width: "62.5rem" }}>
        <Grid
          style={{ maxHeight: "15.625rem", height: "fit-content" }}
          {...this.state.dataState}
          data={this.props.data}
          sortable
          cellRender={defaultRowCell}
          sort={this.state.sort}
          rowRender={this.rowRender}
          onSortChange={(e) => {
            var sortConfig = e.sort[0];
            if (!sortConfig) {
              this.setState({
                sort: [{ data: this.props.data }],
              });
            } else if (sortConfig.field === "formatDateTime") {
              sortConfig = { ...sortConfig, data: this.props.data };
              this.setState({
                sort: [sortConfig],
              });
            }
          }}
        >
          <Column
            field="doc_no"
            title="DOC NO."
            width={(80 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="doc_type"
            title="DOC TYPE"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="vendor_name"
            title="VENDOR"
            width={(120 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="description"
            title="DESCRIPTION"
            headerCell={HeaderRender}
          />
          <Column
            field="created_date"
            title="CREATED DATE"
            width={(140 / 16) * rem + "px"}
            cell={defaultRowDateCell}
            headerCell={HeaderRender}
          />
          <Column
            field="created_by"
            title="CREATED BY"
            width={(120 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="approved_date"
            title="APPROVED DATE"
            width={(140 / 16) * rem + "px"}
            cell={defaultRowDateCell}
            headerCell={HeaderRender}
          />
          <Column
            field="approved_by"
            title="APPROVED BY"
            width={(120 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
        </Grid>
      </div>
    );
  }
}

const defaultRowDateCell = (props) => {
  return (
    <td style={{ fontSize: "0.75rem" }}>
      {FormatDate(props.dataItem[props.field], "DD/MM/YYYY")}
    </td>
  );
};
const defaultRowCell = (tdElement, props) => {
  return <td style={{ fontSize: "0.75rem" }}>{props.dataItem[props.field]}</td>;
};
export { TransactionTable };
