import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FormatDate } from "@aim-mf/styleguide";
import { HeaderRender } from "../components/summary/HeaderRender";
import { claimTableContent, claimTableHeaderMapping } from "./fakeData";

class TransactionTableProfiling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [{ field: "create_datetime", dir: "asc", data: this.props.data }],
    };
  }

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    let { data, header } = this.props;
    return (
      <div style={{ width: "62.5rem" }}>
        <Grid
          style={{ maxHeight: "15.625rem", height: "fit-content" }}
          data={data}
          cellRender={defaultRowCell}
          rowRender={this.rowRender}
        >
          <Column
            field="id"
            title={header.id}
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="date"
            title={header.date}
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="account"
            title={header.account}
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="description"
            title={header.description}
            headerCell={HeaderRender}
          />
          <Column
            field="debit"
            title={header.debit}
            width={(120 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="credit"
            title={header.credit}
            width={(110 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
        </Grid>
      </div>
    );
  }
}

const defaultRowDateCell = (props) => {
  return (
    <td style={{ fontSize: "0.75rem" }}>
      {FormatDate(props.dataItem[props.field], "DD/MM/YYYY")}
    </td>
  );
};
const defaultRowCell = (tdElement, props) => {
  return <td style={{ fontSize: "0.75rem" }}>{props.dataItem[props.field]}</td>;
};
export { TransactionTableProfiling };
