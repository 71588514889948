import React from "react";
import { IconSVG, alertManagementStyle } from "@aim-mf/styleguide";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverOnParent: false,
      hoverID: 1,
    };
  }

  render() {
    if (!this.props.parentData) {
      return <div />;
    }
    return (
      <div name={"SideBar"} style={alertManagementStyle.SideBarStyle}>
        <div
          name={"sidebar-title"}
          style={alertManagementStyle.titleCategoryFont}
        >
          {this.props.menuHeader.toUpperCase()}
        </div>
        <ul name={"sidebar-menu"}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <li
            style={
              this.state.hoverOnParent
                ? alertManagementStyle.MenuWrapperHover
                : alertManagementStyle.MenuWrapper
            }
            onMouseEnter={() => {
              this.setState({ hoverOnParent: true });
            }}
            onMouseLeave={() => {
              this.setState({ hoverOnParent: false });
            }}
            onClick={this.props.handleClickParent}
          >
            {this.state.hoverOnParent && (
              <div style={alertManagementStyle.indicatorBar} />
            )}
            <div style={alertManagementStyle.parentMenuFont}>
              <IconSVG name={"work"} color={"white"} size={"0.6"} />
              {this.props.parentData[this.props.parentNameKey]}
            </div>
          </li>
          {this.props.dataList.map((dataItem) => {
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={dataItem.id}
                style={
                  dataItem.id === this.state.hoverID ||
                  dataItem.id === this.props.focusedID
                    ? alertManagementStyle.MenuWrapperHover
                    : alertManagementStyle.MenuWrapper
                }
                onMouseEnter={() => {
                  this.setState({ hoverID: dataItem.id });
                }}
                onMouseLeave={() => {
                  this.setState({ hoverID: -1 });
                }}
                onClick={() => {
                  sessionStorage.setItem(
                    "alerted_object_id",
                    dataItem.source_id ?? dataItem.id
                  );
                  this.props.handleDataItemClick();
                }}
              >
                {(this.props.focusedID === dataItem.id ||
                  this.state.hoverID === dataItem.id) && (
                  <div style={alertManagementStyle.indicatorBar} />
                )}
                <div
                  style={{
                    ...alertManagementStyle.subMenuFont,
                    ...(this.props.focusedID !== dataItem.id &&
                      this.state.hoverID !== dataItem.id &&
                      alertManagementStyle.longTextHidden),
                  }}
                >
                  &#11172;&nbsp;&nbsp;&nbsp;{dataItem[this.props.dataNameKey]}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export { SideBar };
