import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FormatDate, alertManagementStyle } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";
import { HeaderRender } from "./HeaderRender";

class AlertsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [{ field: "create_datetime", dir: "asc", data: this.props.data }],
    };
  }
  rowClick = (event) => {
    let last = this.lastSelectedIndex;
    const result = [...this.state.result];
    const current = result.findIndex((dataItem) => dataItem === event.dataItem);
    result.forEach((item) => (item.selected = false));
    const select = !event.dataItem.selected;
    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      result[i].selected = select;
    }
    this.setState({ result });
  };

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return (
      <div style={alertManagementStyle.tableWrapper}>
        <Grid
          style={alertManagementStyle.gridStyle}
          {...this.state.dataState}
          data={this.props.data}
          sortable
          onRowClick={this.rowClick}
          sort={this.state.sort}
          rowRender={this.rowRender}
          onSortChange={(e) => {
            var sortConfig = e.sort[0];
            if (!sortConfig) {
              this.setState({
                sort: [{ data: this.props.data }],
              });
            } else if (sortConfig.field === "formatDateTime") {
              sortConfig = { ...sortConfig, data: this.props.data };
              this.setState({
                sort: [sortConfig],
              });
            }
          }}
        >
          <Column
            field="alert_number"
            title="ID"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
            cell={(props) => {
              return (
                <td className="table-risk-id">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div
                    className="table-risk-id div_hover"
                    onClick={() => {
                      navigateToUrl(
                        window.location.origin +
                          "/alert-management/" +
                          props.dataItem.id +
                          "/"
                      );
                      this.props.reloadAll();
                    }}
                  >
                    {props.dataItem["parentRisk_id"] ? "⤷" : " "}
                    {props.dataItem[props.field]}
                  </div>
                </td>
              );
            }}
          />
          <Column
            field="risk"
            title="Risk Name"
            headerCell={HeaderRender}
            width={(150 / 16) * rem + "px"}
          />
          <Column
            field="description"
            title="Description"
            headerCell={HeaderRender}
          />
          <Column
            field="status"
            title="Status"
            headerCell={HeaderRender}
            width={(100 / 16) * rem + "px"}
          />
          <Column
            field="create_datetime"
            title="Date Created"
            width={(200 / 16) * rem + "px"}
            headerCell={HeaderRender}
            cell={(props) => {
              if (props.dataItem[props.field]) {
                return (
                  <td>
                    <div>
                      {FormatDate(
                        props.dataItem[props.field],
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </div>
                  </td>
                );
              }
            }}
          />
          <Column
            field="owners"
            title="Alert Owner"
            width={(150 / 16) * rem + "px"}
            headerCell={HeaderRender}
            cell={(props) => {
              if (props.dataItem[props.field]) {
                return (
                  <td>
                    {props.dataItem[props.field].map((owner) => {
                      return (
                        <div>{owner}</div>
                        // <Pill
                        //   pillStyle={{
                        //     display: "inline-flex",
                        //     marginRight: "0.3rem",
                        //   }}
                        //   content={owner}
                        //   color={"rgba(124,199,255,0.2)"}
                        // />
                      );
                    })}
                  </td>
                );
              } else {
                return <td />;
              }
            }}
          />
        </Grid>
      </div>
    );
  }
}

export { AlertsTable };
