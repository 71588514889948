import {
  AutoSizeTextArea,
  mapping,
  alertManagementStyle,
} from "@aim-mf/styleguide";
import React from "react";

export const ERDemoAlertData = {
  actions: [],
  alert_data_index: "3758",
  alert_data_type: "routineAlert",
  alert_number: "AC3758",
  alert_type: "Control",
  alerted_object_id: "7a6cc2ef-708c-44f4-ab8a-6da237c0253c",
  closed_by: null,
  closed_datetime: null,
  create_datetime: "2020-12-02T06:12:06.819463",
  creator: "SYSTEM",
  description:
    "incidence happened against the control RER-CTRL-MF-02: The Member Firm EIP reviews and approves additional categories of professionals to be added as KICS Persons.",
  due_date: "2020-12-16",
  id: "f976f729-d070-410a-a86e-e78d8e3c5efe",
  is_useful: false,
  owners: ["weidu", "elisaang"],
  parent_risk: null,
  past_owners: [],
  rational: "control failed",
  risk: "Ethical requirements",
  risk_id: "1a40a251-796a-40ec-8063-25aa876515a5",
  status: "open",
  transfer_messages: [],
  transferees: [],
};

export const ERDemoAlertDetailTemplate = (title, data) => {
  return {
    header: title,
    content: data,
  };
};

export const auoSizeQA = (question, answer, index, answerOnChange) => {
  return (
    <div>
      <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
        <div style={{ width: "62.5rem", marginRight: "2.5rem" }}>
          <div
            style={{
              ...mapping["forms/label/1-default"],
              whiteSpace: "pre-wrap",
            }}
          >
            {question}
          </div>
          <AutoSizeTextArea
            value={answer}
            fieldstyle={{
              ...alertManagementStyle.InputFieldStyle,
              width: "100%",
            }}
            disabled={false}
            CustomizedOnChange={(value)=>{answerOnChange(index,value)}}
          />
        </div>
      </div>
    </div>
  );
};

export const ERDemoQuestions = [
  "Reads the list with the conclusion why additional categories are not needed and traces to the underlying input used by the preparer and determines whether documentation sufficiently describes the preparer considerations and their conclusions, and whether each proposed addition is relevant, complete and appropriate",
  "If not already described in the documentation of the review, inquires of the preparer regarding the local inputs considered in reaching the conclusion (and preparing the document, if applicable) and determines whether the minimum inputs were evaluated and whether additional inputs need to be considered by the preparer.",
  "When there were changes, searches in the other local sources independently from the delegate and scans them to determine whether there are additions missing to be proposed and whether the proposed additions reflect all the relevant professional standards and independence requirements.",
  "If revisions to the list are necessary, briefly describes the nature of the revisions identified and corrected in the list from their review or notes that no changes to the list were required from their review that week.",
  "Approves the proposed additions after all appropriate revisions are made to the list of KICS persons.",
  "The EIP requests the delegate to input the approved lists of additional categories of professionals to be added. \n" +
    "[Note to member firms: depending on whether the member firm uses an automated or manual update of KICs, the following two options are available:\n" +
    "-\tMember firm using an automated KICS update – member firm is expected to design a control which ensures all individuals in approved KICS persons categories (as per step 5 above) interface from the HR system to GIS People;\n" +
    "-\tMember firm using a manual KICS update – member firm is expected to design a control which ensures that professionals with the approved KICS categories (as per step 5 above) are added to KICS]\n",
];
