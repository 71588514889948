import React, { useCallback } from "react";
import {
  fetchTaskAPIWithPermission,
  fetchAlertAPIWithPermission,
  fetchAdminAPIWithPermission,
  fetchFraudAPIWithPermission,
  fetchAPIWithPermission,
} from "@aim-mf/api";
import "./app.scss";
import { AlertSummary } from "./page/AlertSummary";
import { AlertManagement } from "./page/components/alertManagement";
import { navigateToUrl } from "single-spa";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";
import { AbcDemoAlertManagement } from "./page/ABCDemoPage/abcDemoAlertManagement";
import {
  ERDemoTriggerRiskName,
  triggerRiskName,
} from "./page/ABCDemoPage/fakeData";
import { ERDemoAlertManagement } from "./page/EthicRequirementDemo/ERDemoAlertManagement";
import {ERDemoAlertData} from "./page/EthicRequirementDemo/ERDemoData"
let fakeVendorTransaction = [
  {
    doc_no: "10185",
    doc_type: "Purchase Order",
    vendor_name: "Samurai Van pte Ltd",
    description: "Monthly IT system maintenance (For FY 2019)",
    created_date: "01/01/2019",
    created_by: "Jess Ng",
    approved_date: "05/01/2019",
    approved_by: "Bill Chan",
  },
  {
    doc_no: "27394",
    doc_type: "Invoice",
    vendor_name: "Samurai Van pte Ltd",
    description: "for services rendered for Jan 2019",
    created_date: "10/01/2019",
    created_by: "Ben Lim",
    approved_date: "13/01/2019",
    approved_by: "Roy Tan",
  },
  {
    doc_no: "10184",
    doc_type: "Invoice",
    vendor_name: "Samurai Van pte Ltd",
    description: "for services rendered for Feb 2019",
    created_date: "18/02/2019",
    created_by: "Harry Lau",
    approved_date: "22/02/2019",
    approved_by: "Bill Chan",
  },
  {
    doc_no: "10186",
    doc_type: "Invoice",
    vendor_name: "Samurai Van pte Ltd",
    description: "for services rendered for Mar 2019",
    created_date: "12/03/2019",
    created_by: "Benjamin Ng",
    approved_date: "15/03/2019",
    approved_by: "Bill Chan",
  },
  {
    doc_no: "10216",
    doc_type: "Invoice",
    vendor_name: "Samurai Van pte Ltd",
    description: "for services rendered for Apr 2019",
    created_date: "10/04/2019",
    created_by: "JHarry Liu",
    approved_date: "12/04/2019",
    approved_by: "Bill Chan",
  },
  {
    doc_no: "12193",
    doc_type: "Payment",
    vendor_name: "ABC Pte Ltd",
    description: "for services rendered for May 2019",
    created_date: "07/06/2019",
    created_by: "Tom Chan",
    approved_date: "09/06/2019",
    approved_by: "Roy Tan",
  },
  {
    doc_no: "12798",
    doc_type: "Payment",
    vendor_name: "ABC Pte Ltd",
    description: "for services rendered for Jun 2019",
    created_date: "28/06/2019",
    created_by: "James Chen",
    approved_date: "29/06/2019",
    approved_by: "Bill Chan",
  },
];

let testingDetailData = {
  name: "Samurai Van",
  vendor_id: "V001",
  created_by: "James Yap",
  address: "@22-22-222 Bedom Street 2 22",
  created_datetime: "12/02/2018",
  email: "JY@Sauraivan.com.sg",
  country: "Singapore",
  postal_code: "222221",
  approved_date: "12/11/2018",
  company_code: "LK130000098",
  telephone: "88899998",
};

const initialState = {
  loading: false,
  loadingUser: true,
  loadingAlert: true,
  loadingAlerts: true,
  loadingTask: true,
  taskList: [],
  userList: [],
  alert: {},
  alertList: [],
  parentRisk: {},
  risk: {},
  alertRiskList: [],
  currentUser: {},
  vendorAnalysis: [],
  routineAlert: {},
};

function App(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  let displaySummary;
  let alertType = sessionStorage.getItem("alert_type");
  let alertedObjectId = sessionStorage.getItem("alerted_object_id");
  let alertedObjectSourceId = sessionStorage.getItem(
    "alerted_object_source_id"
  );
  let typeObjectList = JSON.parse(sessionStorage.getItem("type_object_list"));
  let alertedRisk = JSON.parse(sessionStorage.getItem("alerted_risk"));
  let AlertID = document.location.pathname.split("/");
  AlertID = AlertID[AlertID.length - 2];
  displaySummary = AlertID === "alert-management";

  const {
    loading,
    loadingUser,
    userList,
    loadingAlert,
    loadingAlerts,
    loadingTask,
    taskList,
    alert,
    alertList,
    risk,
    parentRisk,
    currentUser,
    vendorAnalysis,
    routineAlert,
    alertRiskList,
  } = state;

  React.useEffect(() => {
    dispatch({
      type: "loadCurrentUserInfo",
      data: {
        ...JSON.parse(sessionStorage.getItem("CurrentUser")),
        ...JSON.parse(sessionStorage.getItem("MyProfile")),
      },
    });
    if (loadingUser) {
      dispatch({ type: "loadingUser" });
      const userSubscription = fetchAdminAPIWithPermission(
        "auth/profile/list"
      ).subscribe(
        (results) => {
          dispatch({ type: "loadUser", results });
        },
        (err) => {
          console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadUserFunction();
          });
        }
      );
      return () => userSubscription.unsubscribe();
    }

    if (loadingAlerts && displaySummary) {
      dispatch({ type: "resetAlertRisk" });
      dispatch({ type: "loadingAlerts" });
      let data = { alert_type: alertType };
      if (alertedObjectSourceId !== "null")
        data.alerted_object_id = alertedObjectSourceId;
      const userSubscription = fetchAlertAPIWithPermission("getalerts/", {
        data: data,
      }).subscribe(
        (results) => {
          dispatch({ type: "loadAlerts", results });
          let alerts = results.data;
          let riskIDList = alerts.map((alert) => alert.risk_id);
          riskIDList.map((risk_id) => {
            loadingRiskFunction(risk_id);
          });
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadAlertsFunction(alertType, alertedObjectId);
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingAlert && !displaySummary) {
      dispatch({ type: "resetAlertRisk" });
      dispatch({ type: "loadingAlert" });
      const userSubscription = fetchAlertAPIWithPermission(
        "getalert/" + AlertID
      ).subscribe(
        (results) => {
          dispatch({ type: "loadAlert", results });
          let alerts = results.data;
          let risk_id = alerts.risk_id;
          loadingRiskFunction(risk_id);
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadAlertFunction();
          });
        }
      );

      return () => userSubscription.unsubscribe();
    }
    if (loadingTask && !displaySummary) {
      dispatch({ type: "loadingTask" });
      const taskSubscription = fetchTaskAPIWithPermission(
        "gettasks/" + AlertID
      ).subscribe(
        (results) => {
          // eslint-disable-next-line no-console
          dispatch({ type: "loadTask", results });
        },
        (err) => {
                  console.log("err", err); // eslint-disable-line
          AuthCheckAndAutofix(err, () => {
            loadTaskFunction();
          });
        }
      );
      return () => taskSubscription.unsubscribe();
    }
  }, [
    loadingUser,
    loadingAlert,
    loadingTask,
    AlertID,
    loadingAlerts,
    alertType,
    alertedObjectId,
    displaySummary,
    loadUserFunction,
    loadAlertsFunction,
    loadAlertFunction,
    loadTaskFunction,
    loadingRiskFunction,
    alertedObjectSourceId,
  ]);

  const loadUserFunction = useCallback(() => {
    const userSubscription = fetchAdminAPIWithPermission(
      "auth/profile/list"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadUser", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadUserFunction();
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });

  const loadingRiskFunction = useCallback((risk_id) => {
    const subscription = fetchAPIWithPermission(
      "getsubrisks/" + risk_id
    ).subscribe(
      (results) => {
        dispatch({ type: "loadAlertRisk", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadingRiskFunction(risk_id);
        });
      }
    );

    return () => subscription.unsubscribe();
  });

  const loadAlertFunction = useCallback(() => {
    const userSubscription = fetchAlertAPIWithPermission(
      "getalert/" + AlertID
    ).subscribe(
      (results) => {
        dispatch({ type: "loadAlert", results });
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadAlertFunction();
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });

  const loadAlertsFunction = useCallback((alertType, alertedObjectId) => {
    let data = { alert_type: alertType };
    if (alertedObjectId !== "null") data.alerted_object_id = alertedObjectId;
    const userSubscription = fetchAlertAPIWithPermission("getalert/", {
      data: data,
    }).subscribe(
      (results) => {
        dispatch({ type: "loadAlerts", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadAlertsFunction(alertType, alertedObjectId);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  });

  const loadTaskFunction = useCallback(() => {
    const taskSubscription = fetchTaskAPIWithPermission(
      "gettasks/" + AlertID
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        dispatch({ type: "loadTask", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadTaskFunction();
        });
      }
    );
    return () => taskSubscription.unsubscribe();
  });

  const handleCompleteAlert = React.useCallback(() => {
    //console.log(transferee);
    const userSubscription = fetchAlertAPIWithPermission(
      "closealert/" + AlertID
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        dispatch({ type: "loadingAlert" });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleCreateTask = React.useCallback((taskinfo) => {
    taskinfo.data.alert_id = AlertID;
    const userSubscription = fetchTaskAPIWithPermission(
      "tasks/",
      taskinfo
    ).subscribe(
      (results) => {
        // once created, need to reload tasks
        let taskID = results.data.id;
        if (taskinfo.data.attachments.length !== 0) {
          handleTaskAttachmentUpload(taskID, taskinfo.data.attachments);
        } else {
          dispatch({ type: "loadingTask" });
        }
        // console.log(taskinfo.data.to)
        handleAddAlertViwer(taskinfo.data.to, AlertID);
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleCreateTask(taskinfo);
        });
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleTaskAttachmentUpload = (taskID, files) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file.attachment_file);
    });
    const TaskUploadSubscription = fetchTaskAPIWithPermission(
      "uploadattachment/task/" + taskID,
      { data: data }
    ).subscribe(
      (response) => {
        //console.log("upload finished");
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskAttachmentUpload(taskID, files);
        });
      }
    );
    return () => TaskUploadSubscription.unsubscribe();
  };

  const handleActionAttachmentUpload = (action_id, files) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file.attachment_file);
    });
    const taskActionFileUploadSubscription = fetchTaskAPIWithPermission(
      "uploadattachment/taskaction/" + action_id,
      { data: data }
    ).subscribe(
      (response) => {
        //console.log("upload finished");
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleActionAttachmentUpload(action_id, files);
        });
      }
    );
    return () => taskActionFileUploadSubscription.unsubscribe();
  };

  const handleTaskAttachmentDownload = React.useCallback(
    (attachmentID, attachmentFilename) => {
      const attachmentSubscription = fetchTaskAPIWithPermission(
        "task/attachment/" + attachmentID,
        { responseType: "arraybuffer" }
      ).subscribe(
        (response) => {
          let blob = new Blob([response.data], {
            type: extractContentType(response),
          });
          let downloadUrl = window.URL.createObjectURL(blob);
          let filename = attachmentFilename;
          //console.log(blob);

          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            handleTaskAttachmentDownload(attachmentID, attachmentFilename);
          });
        }
      );
      return () => attachmentSubscription.unsubscribe();
    }
  );
  const handleTaskActionAttachmentDownload = React.useCallback(
    (attachmentID, attachmentFilename) => {
      const attachmentSubscription = fetchTaskAPIWithPermission(
        "taskaction/attachment/" + attachmentID,
        { responseType: "arraybuffer" }
      ).subscribe(
        (response) => {
          let blob = new Blob([response.data], {
            type: extractContentType(response),
          });
          let downloadUrl = window.URL.createObjectURL(blob);
          let filename = attachmentFilename;
          //console.log(blob);

          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        },
        (err) => {
          AuthCheckAndAutofix(err, () => {
            handleTaskActionAttachmentDownload(
              attachmentID,
              attachmentFilename
            );
          });
        }
      );
      return () => attachmentSubscription.unsubscribe();
    }
  );

  const handleTaskAttachmentRemove = React.useCallback((attachmentIDList) => {
    const attachmentSubscription = fetchTaskAPIWithPermission(
      "removetaskattachments/",
      { data: attachmentIDList }
    ).subscribe(
      (response) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskAttachmentRemove(attachmentIDList);
        });
      }
    );
    return () => attachmentSubscription.unsubscribe();
  });
  const handleActionAttachmentRemove = React.useCallback((attachmentIDList) => {
    const attachmentSubscription = fetchTaskAPIWithPermission(
      "removeactionattachments/",
      { data: attachmentIDList }
    ).subscribe(
      (response) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleActionAttachmentRemove(attachmentIDList);
        });
      }
    );
    return () => attachmentSubscription.unsubscribe();
  });
  const handleTaskUserSwitch = (task_id) => {
    //console.log(newTask);
    const userSubscription = fetchTaskAPIWithPermission(
      "switchtasktoandfrom/" + task_id
    ).subscribe(
      (results) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskUserSwitch(task_id);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  };

  const handleTaskUpdate = (task_id, newTask) => {
    //console.log(newTask);
    const userSubscription = fetchTaskAPIWithPermission(
      "updatetasks/" + task_id,
      { data: newTask }
    ).subscribe(
      (results) => {
        if (
          newTask.task_new_attachments !== undefined &&
          newTask.task_new_attachments.length !== 0
        ) {
          handleTaskAttachmentUpload(task_id, newTask.task_new_attachments);
        }
        if (newTask.task_remove_attachment_ID.length !== 0) {
          handleTaskAttachmentRemove(newTask.task_remove_attachment_ID);
        }
        newTask.actions.forEach((action) => {
          if (action.action_type === "Upload Request") {
            if (
              action.action_new_attachment !== undefined &&
              action.action_new_attachment.length !== 0
            ) {
              handleActionAttachmentUpload(
                action.action_id,
                action.action_new_attachment
              );
            }
            if (action.action_remove_attachment_ID.length !== 0) {
              handleActionAttachmentRemove(action.action_remove_attachment_ID);
            }
          }
        });
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskUpdate(task_id, newTask);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  };

  const handleTaskClose = (task_id) => {
    const userSubscription = fetchTaskAPIWithPermission(
      "closetask/" + task_id
    ).subscribe(
      (results) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskClose(task_id);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  };
  const handleTaskOpen = (task_id) => {
    const userSubscription = fetchTaskAPIWithPermission(
      "opentask/" + task_id
    ).subscribe(
      (results) => {
        dispatch({ type: "loadingTask" });
      },
      (err) => {
        AuthCheckAndAutofix(err, () => {
          handleTaskOpen(task_id);
        });
      }
    );

    return () => userSubscription.unsubscribe();
  };

  const handleAddAlertViwer = React.useCallback((username, alert_id) => {
    const userSubscription = fetchAlertAPIWithPermission(
      "addalertviewer/" + alert_id,
      { data: username }
    ).subscribe(
      (results) => {
        // once created, need to reload tasks
        //dispatch({ type: "loadingAlert" });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleUpdateAlert = React.useCallback((newalert) => {
    dispatch({ type: "loading" });
    const userSubscription = fetchAlertAPIWithPermission(
      "updatealert/" + AlertID,
      newalert
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        //console.log("---------------------------------");
        //console.log(results.data);
        //console.log(newalert.data);
        let reload = true;
        results.data.actions.map((action, index) => {
          if (action.comment_type === "Upload Request") {
            newalert.data.actions.forEach((actionattachment) => {
              //console.log(actionattachment);

              // when the action is already exist
              if (
                actionattachment.id !== undefined &&
                actionattachment.new_attachments !== undefined
              ) {
                reload = false;
                handleUploadAlertAttachment(
                  actionattachment.new_attachments.map(
                    (a) => a.attachment_file
                  ),
                  actionattachment.id
                );
              }

              // create new action, and upload file at same time.
              if (
                actionattachment.id === undefined &&
                actionattachment.new_attachments !== undefined &&
                actionattachment.comment_type === "Upload Request" &&
                actionattachment.comment === action.comment
              ) {
                reload = false;
                handleUploadAlertAttachment(
                  actionattachment.new_attachments.map(
                    (a) => a.attachment_file
                  ),
                  action.id
                );
              }
            });
          }
        });
        newalert.data.actions.forEach((action) => {
          if (
            action.comment_type === "Upload Request" &&
            action.removeAttachmentID !== undefined
          ) {
            reload = false;
            handleRemoveAlertAttachment(action.removeAttachmentID);
          }
        });
        if (reload) {
          dispatch({ type: "loadingAlert" });
        }
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const handleRemoveAlertAttachment = React.useCallback((idList) => {
    const userSubscription = fetchAlertAPIWithPermission("removeattachment/", {
      data: idList,
    }).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        console.log("delete success");
        dispatch({ type: "loadingAlert" });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });
  const handleUploadAlertAttachment = React.useCallback((files, id) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("files", file);
    });
    const userSubscription = fetchAlertAPIWithPermission(
      "uploadattachment/alertaction/" + id,
      { data: data }
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        console.log("upload success");
        dispatch({ type: "loadingAlert" });
      },
      (err) => {
              console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  const extractContentType = (response) => {
    //console.log(response);
    return response.headers["content-type"];
  };

  const handleAttachmentDownload = React.useCallback(
    (attachmentID, attachmentFilename) => {
      const attachmentSubscription = fetchAlertAPIWithPermission(
        "alertaction/attachment/" + attachmentID,
        { responseType: "arraybuffer" }
      ).subscribe(
        (response) => {
          let blob = new Blob([response.data], {
            type: extractContentType(response),
          });
          let downloadUrl = window.URL.createObjectURL(blob);
          let filename = attachmentFilename;
          //console.log(blob);

          let a = document.createElement("a");
          if (typeof a.download === "undefined") {
            window.location.href = downloadUrl;
          } else {
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
          }
        },
        (err) => {
              console.log("err", err); // eslint-disable-line
        }
      );
      return () => attachmentSubscription.unsubscribe();
    }
  );

  const handleReportAlert = React.useCallback(
    (riskManager, transferComment) => {
      //console.log(riskManager)
      dispatch({ type: "loading" });
      //console.log(transferComment);
      const userSubscription = fetchAlertAPIWithPermission(
        "reportalert/" + AlertID,
        { data: { username: riskManager[0], comment: transferComment } }
      ).subscribe(
        (results) => {
          // eslint-disable-next-line no-console
          console.log("report success");
          dispatch({ type: "loadingAlert" });
        },
        (err) => {
              console.log("err", err); // eslint-disable-line
        }
      );
      return () => userSubscription.unsubscribe();
    }
  );

  const reloadAll = React.useCallback(() => {
    alertType = sessionStorage.getItem("alert_type");
    alertedObjectId = sessionStorage.getItem("alerted_object_id");
    alertedObjectSourceId = sessionStorage.getItem("alerted_object_source_id");
    typeObjectList = JSON.parse(sessionStorage.getItem("type_object_list"));

    AlertID = document.location.pathname.split("/");
    AlertID = AlertID[AlertID.length - 2];
    if (AlertID === "alert-management") {
      displaySummary = true;
      dispatch({ type: "loadingAlerts" });
    } else {
      displaySummary = false;
      AlertID = AlertID[AlertID.length - 2];
      dispatch({ type: "loadingAlert" });
      dispatch({ type: "loadingTask" });
    }
  });

  const handleTransferAlert = React.useCallback(
    (transferee, transferComment) => {
      dispatch({ type: "loading" });
      //console.log(transferComment);
      const userSubscription = fetchAlertAPIWithPermission(
        "transferalert/" + AlertID,
        { data: { username: transferee[0], comment: transferComment } }
      ).subscribe(
        (results) => {
          // eslint-disable-next-line no-console
          console.log("transfered success");
          dispatch({ type: "loadingAlert" });
        },
        (err) => {
          console.log("err", err); // eslint-disable-line
        }
      );
      return () => userSubscription.unsubscribe();
    }
  );

  const handleAcceptTransferAlert = React.useCallback(() => {
    //console.log(transferComment);
    dispatch({ type: "loading" });
    const userSubscription = fetchAlertAPIWithPermission(
      "acceptalert/" + AlertID
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        console.log("accept transferred success");
        dispatch({ type: "loadingAlert" });
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });
  const handleDeclineTransferAlert = React.useCallback((username, msg) => {
    //console.log(msg);
    dispatch({ type: "loading" });
    const userSubscription = fetchAlertAPIWithPermission(
      "rejectalert/" + AlertID,
      { data: { username: username, comment: msg } }
    ).subscribe(
      (results) => {
        // eslint-disable-next-line no-console
        console.log("reject transferred success");
        navigateToUrl("/task-management");
      },
      (err) => {
        console.log("err", err); // eslint-disable-line
      }
    );
    return () => userSubscription.unsubscribe();
  });

  // plugin functions for loading vendor analysis model
  const loadingAlertDataDetail = useCallback(() => {
    const userSubscription = fetchFraudAPIWithPermission(
      "api/vendoranalysis"
    ).subscribe(
      (results) => {
        dispatch({ type: "loadAlertData", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadingAlertDataDetail();
        });
      }
    );
    return () => userSubscription.unsubscribe();
  });

  // plugin functions for loading vendor analysis model
  const loadingAlertDataDetailRoutineAlert = useCallback((routineAlertID) => {
    const userSubscription = fetchFraudAPIWithPermission("api/routinealert", {
      data: { id: routineAlertID },
    }).subscribe(
      (results) => {
        dispatch({ type: "loadAlertDataRoutineAlert", results });
      },
      (err) => {
          console.log("err", err); // eslint-disable-line
        AuthCheckAndAutofix(err, () => {
          loadingAlertDataDetailRoutineAlert();
        });
      }
    );
    return () => userSubscription.unsubscribe();
  });

  let abc_demo = false;
  if (triggerRiskName.includes(alertedRisk.name)) abc_demo = true;

  let er_demo = false;
  if (ERDemoTriggerRiskName.includes(alertedRisk.name)) er_demo = true;

  if (er_demo) {
    return (
      <ERDemoAlertManagement
        alertType={alertType}
        typeObjectList={typeObjectList}
        alertedObjectId={alertedObjectId}
        IAERisk={alertRiskList[0]}
        vendorDetailData={testingDetailData}
        data={fakeVendorTransaction}
        reportTargetTitle={"Risk Manager"}
        loading={loading}
        handleTransferAlert={handleTransferAlert}
        currentUser={currentUser}
        handleUpdateAlert={handleUpdateAlert}
        userList={userList}
        taskList={taskList}
        alert={ERDemoAlertData}
        alertList={alertList}
        parentRisk={parentRisk}
        handleCompleteAlert={handleCompleteAlert}
        // handleCreateTask={handleCreateTask}
        handleAttachmentDownload={handleAttachmentDownload}
        handleAcceptTransferAlert={handleAcceptTransferAlert}
        handleDeclineTransferAlert={handleDeclineTransferAlert}
        handleReportAlert={handleReportAlert}
        reloadAll={reloadAll}
        handleCreateTask={handleCreateTask}
        handleTaskUpdate={handleTaskUpdate}
        handleTaskClose={handleTaskClose}
        handleTaskOpen={handleTaskOpen}
        handleTaskUserSwitch={handleTaskUserSwitch}
        handleTaskAttachmentDownload={handleTaskAttachmentDownload}
        handleTaskActionAttachmentDownload={handleTaskActionAttachmentDownload}
        handleTaskAttachmentRemove={handleTaskAttachmentRemove}
        handleActionAttachmentRemove={handleActionAttachmentRemove}
        vendorAnalysis={vendorAnalysis}
        loadingAlertDataDetail={loadingAlertDataDetail}
        routineAlert={routineAlert}
        loadingAlertDataDetailRoutineAlert={loadingAlertDataDetailRoutineAlert}
      />
    );
  }

  return (
    <div>
      {displaySummary && (
        <AlertSummary
          riskList={alertRiskList}
          risk={alertedRisk}
          alertList={alertList}
          alertType={alertType}
          alertedObjectId={alertedObjectId}
          typeObjectList={typeObjectList}
          reloadAll={reloadAll}
          abc_demo={abc_demo}
        />
      )}
      {!displaySummary && !abc_demo && (
        <AlertManagement
          alertType={alertType}
          typeObjectList={typeObjectList}
          alertedObjectId={alertedObjectId}
          IAERisk={alertRiskList[0]}
          vendorDetailData={testingDetailData}
          data={fakeVendorTransaction}
          reportTargetTitle={"Risk Manager"}
          loading={loading}
          handleTransferAlert={handleTransferAlert}
          currentUser={currentUser}
          handleUpdateAlert={handleUpdateAlert}
          userList={userList}
          taskList={taskList}
          alert={alert}
          alertList={alertList}
          parentRisk={parentRisk}
          handleCompleteAlert={handleCompleteAlert}
          // handleCreateTask={handleCreateTask}
          handleAttachmentDownload={handleAttachmentDownload}
          handleAcceptTransferAlert={handleAcceptTransferAlert}
          handleDeclineTransferAlert={handleDeclineTransferAlert}
          handleReportAlert={handleReportAlert}
          reloadAll={reloadAll}
          handleCreateTask={handleCreateTask}
          handleTaskUpdate={handleTaskUpdate}
          handleTaskClose={handleTaskClose}
          handleTaskOpen={handleTaskOpen}
          handleTaskUserSwitch={handleTaskUserSwitch}
          handleTaskAttachmentDownload={handleTaskAttachmentDownload}
          handleTaskActionAttachmentDownload={
            handleTaskActionAttachmentDownload
          }
          handleTaskAttachmentRemove={handleTaskAttachmentRemove}
          handleActionAttachmentRemove={handleActionAttachmentRemove}
          vendorAnalysis={vendorAnalysis}
          loadingAlertDataDetail={loadingAlertDataDetail}
          routineAlert={routineAlert}
          loadingAlertDataDetailRoutineAlert={
            loadingAlertDataDetailRoutineAlert
          }
        />
      )}
      {!displaySummary && abc_demo && (
        <AbcDemoAlertManagement
          alertType={alertType}
          typeObjectList={typeObjectList}
          alertedObjectId={alertedObjectId}
          IAERisk={alertRiskList[0]}
          vendorDetailData={testingDetailData}
          data={fakeVendorTransaction}
          reportTargetTitle={"Risk Manager"}
          loading={loading}
          handleTransferAlert={handleTransferAlert}
          currentUser={currentUser}
          handleUpdateAlert={handleUpdateAlert}
          userList={userList}
          taskList={taskList}
          alert={alert}
          alertList={alertList}
          parentRisk={parentRisk}
          handleCompleteAlert={handleCompleteAlert}
          // handleCreateTask={handleCreateTask}
          handleAttachmentDownload={handleAttachmentDownload}
          handleAcceptTransferAlert={handleAcceptTransferAlert}
          handleDeclineTransferAlert={handleDeclineTransferAlert}
          handleReportAlert={handleReportAlert}
          reloadAll={reloadAll}
          handleCreateTask={handleCreateTask}
          handleTaskUpdate={handleTaskUpdate}
          handleTaskClose={handleTaskClose}
          handleTaskOpen={handleTaskOpen}
          handleTaskUserSwitch={handleTaskUserSwitch}
          handleTaskAttachmentDownload={handleTaskAttachmentDownload}
          handleTaskActionAttachmentDownload={
            handleTaskActionAttachmentDownload
          }
          handleTaskAttachmentRemove={handleTaskAttachmentRemove}
          handleActionAttachmentRemove={handleActionAttachmentRemove}
          vendorAnalysis={vendorAnalysis}
          loadingAlertDataDetail={loadingAlertDataDetail}
          routineAlert={routineAlert}
          loadingAlertDataDetailRoutineAlert={
            loadingAlertDataDetailRoutineAlert
          }
        />
      )}
    </div>
  );

  function reducer(state = initialState, action) {
    switch (action.type) {
      case "loading":
        //console.log("start loading");
        return {
          ...state,
          loading: true,
        };
      case "loadCurrentUserInfo":
        return {
          ...state,
          currentUser: action.data,
        };
      case "loadingUser":
        // console.log("in loading riskList")
        return { ...state, loadingUser: true };
      case "loadingAlert":
        // console.log("in loading riskList")
        return { ...state, loadingAlert: true, loadingAlerts: false };
      case "loadingAlerts":
        // console.log("in loading riskList")
        return { ...state, loadingAlerts: true, loadingAlert: false };
      case "loadingTask":
        // console.log("in loading riskList")
        return { ...state, loadingTask: true };

      case "loadUser":
        return {
          ...state,
          userList: action.results.data,
          loadingUser: false,
          loading: false,
        };
      case "loadTask":
        return {
          ...state,
          taskList: action.results.data,
          loadingTask: false,
          loading: false,
        };
      case "loadAlert":
        //console.log("load alert");
        let alert = action.results.data;
        alert.alert_number = alert.alert_number.split("--")[0];
        return {
          ...state,
          alert: alert,
          loadingAlert: false,
          loading: false,
        };
      case "loadAlerts":
        let alerts = action.results.data;
        alerts.map((alert) => {
          alert.alert_number = alert.alert_number.split("--")[0];
        });
        return {
          ...state,
          alertList: alerts,
          loadingAlerts: false,
          loading: false,
        };
      case "loadAlertData":
        //console.log("load alert");
        return {
          ...state,
          vendorAnalysis: action.results.data,
        };

      case "loadRisk":
        return {
          ...state,
          risk: action.results.data,
          loading: false,
        };
      case "loadParentRisk":
        return {
          ...state,
          parentRisk: action.results.data,
          loading: false,
        };
      case "loadAlertDataRoutineAlert":
        return {
          ...state,
          routineAlert: action.results.data,
        };
      case "loadAlertRisk":
        let list = state.alertRiskList;
        list.push(action.results.data);
        return {
          ...state,
          alertRiskList: list,
        };
      case "resetAlertRisk":
        return {
          ...state,
          alertRiskList: [],
        };
      default:
        throw Error(`Unknown action type '${action.type}'`);
    }
  }
}

export default App;
