import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FormatDate } from "@aim-mf/styleguide";
import { HeaderRender } from "../components/summary/HeaderRender";
import { claimTableContent, claimTableHeaderMapping } from "./fakeData";

class ClaimTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [{ field: "create_datetime", dir: "asc", data: this.props.data }],
    };
  }

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return (
      <div style={{ width: "62.5rem" }}>
        <Grid
          style={{ maxHeight: "15.625rem", height: "fit-content" }}
          data={claimTableContent}
          cellRender={defaultRowCell}
          rowRender={this.rowRender}
        >
          <Column
            field="id"
            title={claimTableHeaderMapping.id}
            width={(60 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="type"
            title={claimTableHeaderMapping.type}
            width={(170 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="claim_date"
            title={claimTableHeaderMapping.claim_date}
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="description"
            title={claimTableHeaderMapping.description}
            headerCell={HeaderRender}
          />
          <Column
            field="created_date"
            title={claimTableHeaderMapping.created_date}
            width={(120 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="created_by"
            title={claimTableHeaderMapping.created_by}
            width={(110 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="approved_date"
            title={claimTableHeaderMapping.approved_date}
            width={(120 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="approved_by"
            title={claimTableHeaderMapping.approved_by}
            width={(110 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="amount"
            title={claimTableHeaderMapping.amount}
            width={(80 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
        </Grid>
      </div>
    );
  }
}

const defaultRowDateCell = (props) => {
  return (
    <td style={{ fontSize: "0.75rem" }}>
      {FormatDate(props.dataItem[props.field], "DD/MM/YYYY")}
    </td>
  );
};
const defaultRowCell = (tdElement, props) => {
  return <td style={{ fontSize: "0.75rem" }}>{props.dataItem[props.field]}</td>;
};
export { ClaimTable };
