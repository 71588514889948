import React from "react";
import { AlertBasicInfo } from "../components/alertComponents/alertBasicInfo";
import { AlertVendorDetail } from "../components/alertComponents/alertDetailComponent/alertVendorDetail";
import { TaskReference } from "../components/alertComponents/taskReference";
import { TransferAlertPopup } from "../components/popups/transferAlertPopup";
import { ReportAlertPopup } from "../components/popups/reportAlertPopup";
import {
  ButtonSolid,
  IconButtonSolid,
  IconSVG,
  mapping,
  FormatDate,
  TaskForm,
  ConfirmationPopup,
  ViewTask,
  Loading,
  alertManagementStyle,
  NetworkGraph,
} from "@aim-mf/styleguide";
import { DeclineTransferPopup } from "../components/popups/declineTransferPopup";
import { navigateToUrl } from "single-spa";
import { VendorAnalysisDetailTE } from "./vendorAnalysisDetailTE";
import { RoutineAlertDetail } from "../components/alertComponents/routineAlertDataSource/routineAlertDetail";
import { AlertIAEDetail } from "../components/alertComponents/alertIAEDetail";
import { AlertKRIDetail } from "../components/alertComponents/alertDetailComponent/KRIAlert/alertKRIDetail";
import {
  fakeAlertVendorDetailContent,
  fakeAlertVendorDetailHeader,
  networkGraphData,
} from "./fakeData";
import { AlertVendorDetailTE } from "./alertVendorDetailTE";
import { AlertVendorDetailProfiling } from "./alertVendorDetailProfiling";
import { VendorAnalysisDetailProfiling } from "./vendorAnalysisDetailProfiling";
import { VendorAnalysisDetail } from "../components/alertComponents/alertDataSource/vendorAnalysisDetail";
import { AlertVendorDetailThirdPartyPayment } from "./alertVendorDetailThirdPartyPayment";
import { VendorAnalysisDetailThirdPartyPayment } from "./vendorAnalysisDetailThirdPartyPayment";

class AbcDemoAlertManagement extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.alert);
    this.state = {
      createTask: false,
      transfer: false,
      report: false,
      declineTransfer: false,
      Confirmation: { display: false },
      alert: {},
      taskList: this.props.taskList,
      warning: { display: false },
      viewTask: { display: false },
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (JSON.stringify(nextProps.alert) !== JSON.stringify(this.props.alert)) {
      //console.log(nextProps.alert);
      this.setState({
        alert: nextProps.alert,
      });
    }
  }

  componentDidUpdate() {
    if (this.props.vendorAnalysis.length === 0) {
      this.props.loadingAlertDataDetail();
    }
    if (
      Object.keys(this.props.routineAlert).length === 0 &&
      this.props.alert.alert_data_type === "routineAlert"
    ) {
      this.props.loadingAlertDataDetailRoutineAlert(
        this.props.alert.alert_data_index
      );
    }
  }

  render() {
    if (Object.keys(this.state.alert).length === 0) {
      return <div />;
    }

    let newDemoAlert = this.state.alert;
    if (this.state.alert.risk === "Travel and Entertainment") {
      newDemoAlert.description =
        "Higher level of expenditure compared to other employees in the same role​";
      newDemoAlert.alert_number = "A004";
    }
    if (this.state.alert.risk === "Profiling") {
      newDemoAlert.description =
        "Same bank account number as Employee Terry Johnson​​";
      newDemoAlert.alert_number = "A003";
    }
    if (this.state.alert.risk === "Link Analysis") {
      newDemoAlert.description =
        "Same bank account number as Employee Terry Johnson​​";
      newDemoAlert.alert_number = "A003";
    }
    if (this.state.alert.risk === "Third-party Payments") {
      newDemoAlert.description = "Anomalous Vendor";
      newDemoAlert.alert_number = "A001";
    }

    return (
      <div>
        <div
          style={
            this.state.createTask ||
            this.state.transfer ||
            this.state.report ||
            this.state.viewTask.display ||
            this.state.declineTransfer ||
            this.props.loading
              ? {
                  ...alertManagementStyle.BlurEffectStyle,
                  width: "100%",
                  paddingLeft: "5.625rem",
                  paddingBottom: "1.875rem",
                }
              : {
                  width: "100%",
                  paddingLeft: "5.625rem",
                  paddingBottom: "1.875rem",
                }
          }
        >
          <div style={alertManagementStyle.breadCumStyle}>
            <IconSVG name={"home"} color={mapping["Color_Basic_Primary"]} />
            <div
              style={{
                ...mapping["symbols/breadcrumbs/active"],
                color: "#00A2FF",
              }}
            >
              My task
            </div>
            <div
              style={{
                ...mapping["symbols/breadcrumbs/active"],
                color: "#6C757D",
              }}
            >
              / Alert
            </div>
          </div>
          <div style={{ marginBottom: "2.6875rem" }}>
            <div style={{ display: "inline-flex" }}>
              <ButtonSolid
                name={"Back"}
                color={"Secondary"}
                width={alertManagementStyle.buttonWidth}
                height={alertManagementStyle.buttonHeight}
                clickEvent={() => {
                  navigateToUrl(location.origin + "/task-management");
                }}
              />
              <div style={{ width: "80rem" }} />
              <IconButtonSolid
                name={"Report this to Risk Manager"}
                size={"Small"}
                color={"Warning"}
                width={alertManagementStyle.reportButtonWidth}
                height={alertManagementStyle.buttonHeight}
                iconPosr={"Left"}
                icon={<IconSVG name={"warning"} size={0.6} />}
                clickEvent={
                  this.state.createTask || this.state.transfer
                    ? {}
                    : this.handleReportAlert
                }
                disabled={
                  this.props.alert.owners.indexOf(
                    this.props.currentUser.username
                  ) === -1 || this.props.alert.status === "closed"
                }
              />
              <div style={{ width: "20px" }} />
              <IconButtonSolid
                name={"Transfer Alert"}
                size={"Small"}
                color={"Danger"}
                width={alertManagementStyle.transferButtonWidth}
                height={alertManagementStyle.buttonHeight}
                iconPosr={"Left"}
                icon={<IconSVG name={"people"} size={0.6} />}
                clickEvent={
                  this.state.createTask || this.state.report
                    ? {}
                    : this.handleTransferAlert
                }
                disabled={
                  this.props.alert.owners.indexOf(
                    this.props.currentUser.username
                  ) === -1 || this.props.alert.status === "closed"
                }
              />
            </div>
          </div>
          <div style={{ display: "inline-flex" }}>
            <div style={{ marginRight: "3.75rem" }}>
              <AlertBasicInfo
                alert={newDemoAlert}
                uploadingFile={this.state.alert.actions.map(
                  (action) => action.attachment_name
                )}
                currentUser={this.props.currentUser}
                userList={this.props.userList}
                parentRisk={this.props.parentRisk}
                handleAddAction={this.handleAddAction}
                handleDeleteAction={this.handleDeleteAction}
                handleSelectAction={this.handleSelectAction}
                onChangeTextField={this.onChangeTextField}
                handleRationalChange={this.handleRationalChange}
                handleAlertComplete={this.handleAlertComplete}
                handleSaveChanges={this.handleSaveChanges}
                handleUsefulChange={this.handleUsefulChange}
                handleAttachmentChange={this.handleAttachmentChange}
                handleRemoveUploadFile={this.handleRemoveUploadFile}
                handleAttachmentDownload={this.props.handleAttachmentDownload}
                handleAcceptTransferAlert={this.props.handleAcceptTransferAlert}
                handleConfirmation={this.handleConfirmation}
                handleDeclineTransferPopup={this.handleDeclineTransferPopup}
                editable={
                  this.props.alert.owners.indexOf(
                    this.props.currentUser.username
                  ) !== -1 && this.props.alert.status === "open"
                }
              />
              <div style={{ height: "2.5rem" }} />
              <TaskReference
                task={this.props.taskList}
                userList={this.props.userList}
                currentUser={this.props.currentUser}
                handleCreateTask={
                  this.state.report || this.state.transfer
                    ? {}
                    : this.handleCreateTask
                }
                handleViewTask={
                  this.state.report || this.state.transfer
                    ? {}
                    : (content) => {
                        this.handleViewTask(content);
                      }
                }
                editable={
                  this.props.alert.owners.indexOf(
                    this.props.currentUser.username
                  ) !== -1 && this.props.alert.status === "open"
                }
              />
            </div>
            {this.props.alert.risk === "Travel and Entertainment" && (
              <div style={alertManagementStyle.alertManagementWrapperStyle}>
                <AlertVendorDetailTE
                  alert={this.props.alert}
                  header={fakeAlertVendorDetailHeader[this.props.alert.id]}
                  content={fakeAlertVendorDetailContent[this.props.alert.id]}
                />
                <VendorAnalysisDetailTE
                  data={this.props.data}
                  alertDetail={this.props.alert}
                  alertData={this.props.vendorAnalysis}
                />
              </div>
            )}
            {(this.props.alert.risk === "Profiling" ||
              this.props.alert.risk === "Link Analysis") && (
              <div style={alertManagementStyle.alertManagementWrapperStyle}>
                <AlertVendorDetailProfiling
                  alert={this.props.alert}
                  header={fakeAlertVendorDetailHeader[this.props.alert.id]}
                  content={fakeAlertVendorDetailContent[this.props.alert.id][0]}
                  name={"Mary Chia"}
                  header_dispaly={true}
                />
                <AlertVendorDetailProfiling
                  alert={this.props.alert}
                  header={fakeAlertVendorDetailHeader[this.props.alert.id]}
                  content={fakeAlertVendorDetailContent[this.props.alert.id][1]}
                  name={"Terry Johnson"}
                />
                <VendorAnalysisDetailProfiling
                  data={this.props.data}
                  alertDetail={this.props.alert}
                  alertData={this.props.vendorAnalysis}
                />

                <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
                  <div style={alertManagementStyle.sectionTitleStyle}>
                    Relation Network
                  </div>
                  <NetworkGraph
                    data={networkGraphData}
                    nodeIDName={"id"}
                    nodeLabelName={"name"}
                    nodeValueName={"value"}
                    nodeColorName={"color"}
                    nodeToName={"to"}
                    nodeLegendTextName={"type"}
                    arrows={""}
                    noDirection={true}
                  />
                </div>
              </div>
            )}

            {this.props.alert.risk === "Third-party Payments" && (
              <div style={alertManagementStyle.alertManagementWrapperStyle}>
                <AlertVendorDetailThirdPartyPayment
                  alert={this.props.alert}
                  header={fakeAlertVendorDetailHeader[this.props.alert.id]}
                  content={fakeAlertVendorDetailContent[this.props.alert.id]}
                  name={"James Yap"}
                />
                <VendorAnalysisDetailThirdPartyPayment
                  data={this.props.data}
                  alertDetail={this.props.alert}
                  alertData={this.props.vendorAnalysis}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.createTask && (
          <TaskForm
            userList={this.props.userList}
            cancelFunction={() => {
              this.setState({ createTask: false });
            }}
            submitFunction={this.handleCreateTaskAPICall}
            currentUser={this.props.currentUser.username}
            // handleAttachmentChange={this.handleAttachmentChange}
            handleRemoveUploadFile={this.handleTaskRemoveUploadFile}
          />
        )}
        {this.state.viewTask.display && (
          <ViewTask
            userList={this.props.userList}
            taskCardData={this.state.viewTask.content}
            uploadingFile={this.state.viewTask.content.uploadingFile}
            currentUser={this.props.currentUser}
            cancelFunction={this.handleViewTaskClose}
            handleViewTaskSave={this.handleViewTaskSave}
            handleTaskClose={this.handleTaskClose}
            handleTaskOpen={this.handleTaskOpen}
            viewHistory={false}
            handleTaskUserSwitch={this.props.handleTaskUserSwitch}
            // handleAttachmentChange={this.handleAttachmentChange}
            handleRemoveUploadFile={this.handleTaskRemoveUploadFile}
            handleTaskAttachmentDownload={
              this.props.handleTaskAttachmentDownload
            }
            handleTaskActionAttachmentDownload={
              this.props.handleTaskActionAttachmentDownload
            }
            handleActionAttachmentRemove={
              this.props.handleActionAttachmentRemove
            }
            disabled={
              !this.state.viewTask.content.to.includes(
                this.props.currentUser.username
              ) || this.state.viewTask.content.status === "closed"
            }
            taskAttachmentDisabled={
              this.props.currentUser.username !==
                this.state.viewTask.content.creator ||
              this.state.viewTask.content.status === "closed"
            }
            submitDisabled={
              !this.state.viewTask.content.to.includes(
                this.props.currentUser.username
              )
            }
            taskCloseDisabled={
              this.props.currentUser.username !==
              this.state.viewTask.content.creator
            }
            taskSaveDisabled={
              this.props.currentUser.username !==
                this.state.viewTask.content.creator &&
              !this.state.viewTask.content.to.includes(
                this.props.currentUser.username
              ) &&
              !this.state.viewTask.content.from ===
                this.props.currentUser.username
            }
          />
        )}
        {this.state.transfer && (
          <TransferAlertPopup
            userList={this.props.userList}
            cancelFunction={() => {
              this.setState({ transfer: false });
            }}
            submitFunction={this.handleSubmitTransferAlert}
          />
        )}
        {this.state.report && (
          <ReportAlertPopup
            riskManager={this.props.userList.filter((user) => {
              //console.log(user.title)
              //console.log(this.props.reportTargetTitle)
              return user.title === this.props.reportTargetTitle;
            })}
            cancelFunction={() => {
              this.setState({ report: false });
            }}
            submitFunction={this.handleSubmitReportAlert}
          />
        )}
        {this.state.declineTransfer && (
          <DeclineTransferPopup
            cancelFunction={() => {
              this.setState({ declineTransfer: false });
            }}
            submitFunction={(msg) => {
              this.props.handleDeclineTransferAlert(
                this.props.alert.past_owners[
                  this.props.alert.past_owners.length - 1
                ],
                msg
              );
              this.setState({ declineTransfer: false });
            }}
          />
        )}
        {this.state.warning.display && (
          <ConfirmationPopup
            title={this.state.warning.title}
            content={this.state.warning.content}
            cancelFunction={() => {
              this.setState({ warning: { display: false } });
            }}
            confirmFunction={() => {
              this.state.warning.confirmFunction();
              this.setState({ warning: { display: false } });
            }}
          />
        )}

        {this.props.loading && (
          <Loading type={"bars"} color={mapping["Color_Basic_Primary"]} />
        )}
      </div>
    );
  }

  handleTaskRemoveUploadFile = (actionIndex, index) => {
    let uploadingFile = this.state.uploadingFile;
    uploadingFile.splice(index, 1);

    let alert = this.state.alert;
    alert.actions[index].attachment_file = uploadingFile;
    alert.actions[index].attachment_name = uploadingFile.map(
      (file) => file.name
    );
    alert.actions[index].attachment_content_type = uploadingFile.map(
      (file) => file.type
    );
    this.setState({ alert: alert });
  };

  handleCreateTaskAPICall = (task) => {
    let taskPackage = { data: task };
    this.props.handleCreateTask(taskPackage);
    this.setState({ createTask: false });
  };
  handleViewTaskClose = () => {
    this.setState({ viewTask: { display: false, Detail: {} } });
  };
  handleViewTaskSave = (task) => {
    //console.log(this.mappingFrontendToBackend(task));
    this.props.handleTaskUpdate(task.id, this.mappingFrontendToBackend(task));
    this.setState({ viewTask: { display: false, Detail: {} } });
  };

  handleTaskClose = (taskID) => {
    this.setState({ viewTask: { display: false, Detail: {} } });
    this.props.handleTaskClose(taskID);
  };
  handleTaskOpen = (taskID) => {
    this.setState({ viewTask: { display: false, Detail: {} } });
    this.props.handleTaskOpen(taskID);
  };
  mappingFrontendToBackend = (frontendData) => {
    //console.log(frontendData);
    let comments = [];
    frontendData.comments.forEach((comment) => {
      //console.log(FormatDate(comment.dateTime, "YYYY-MM-DD HH:mm:ss"));
      comments.push({
        content: comment.commentText,
        username: comment.userName,
        time: FormatDate(comment.dateTime, "YYYY-MM-DD HH:mm:ss"),
      });
    });
    let actions = [];
    frontendData.actions.forEach((action) => {
      //console.log(action);
      actions.push({
        action_id: action.actionID,
        action_type: action.actionType,
        action_subtype: action.actionContent[0].type,
        action_question:
          action.actionContent[0].description ||
          action.actionContent[0].question,
        action_answer: action.actionContent[0].answer,
        action_options: action.actionContent[0].options,
        action_choices: action.actionContent[0].selected
          ? Array.isArray(action.actionContent[0].selected)
            ? action.actionContent[0].selected
            : [action.actionContent[0].selected]
          : [],
        action_new_attachment: action.newAttchments,
        action_remove_attachment_ID:
          action.actionContent[0].removeActionAttachmentID,
      });
    });
    let taskContent = {
      due_date: frontendData.dueDate || FormatDate(new Date(), "YYYY-MM-DD"),
      title: frontendData.title,
      description: frontendData.description,
      actions: actions,
      task_comment: comments,
      task_from: this.props.currentUser.username,
      task_new_attachments: frontendData.newAttchments,
      task_remove_attachment_ID: frontendData.removeTaskAttachmentID,
      //eventDeleteAction: this.state.eventDeleteAction
    };

    return taskContent;
  };

  handleAttachmentChange = (index, e) => {
    //console.log(index, this.state.alert.actions[index]);
    let uploadFile = this.state.alert.actions[index].newAttachments;
    let uploadingFile = uploadFile ? uploadFile : [];
    let file;
    if (e.target) {
      file = e.target.files[0];
      if (file) {
        uploadingFile.push({
          attachment_file: file,
          name: file.name,
          content_type: file.type,
        });
      }
    } else {
      if (e[0] !== undefined) {
        //console.log(e[0]);
        file = e[0];
        uploadingFile.push({
          attachment_file: file,
          name: file.name,
          content_type: file.type,
        });
      }
    }

    let alert = this.state.alert;
    alert.actions[index].newAttachments = uploadingFile;
    alert.actions[index].attachments.push(file);
    this.setState({ alert: alert });
  };

  handleConfirmation = (title, content, confirmFunction) => {
    this.setState({
      warning: {
        display: true,
        title: title,
        content: content,
        confirmFunction: confirmFunction,
      },
    });
  };

  handleDeclineTransferPopup = () => {
    this.setState({ declineTransfer: true });
  };

  handleRemoveUploadFile = (actionIndex, index) => {
    let alert = this.state.alert;
    let action = alert.actions[actionIndex];
    let attachments = action.attachments;
    let newAttachments = action.newAttachments;
    let offset = attachments.length - newAttachments.length;
    let removeAttachmentID = action.removeAttachmentID;
    removeAttachmentID = removeAttachmentID ? removeAttachmentID : [];
    if (attachments[index].id) {
      removeAttachmentID.push(attachments[index].id);
    }
    newAttachments.splice(index - offset, 1);
    attachments.splice(index, 1);

    action.removeAttachmentID = removeAttachmentID;
    this.setState({ alert: alert });
  };

  handleSubmitTransferAlert = (username, transferComment) => {
    //console.log(username);
    this.props.handleTransferAlert(username, transferComment);
    this.setState({ transfer: false });
  };
  handleSubmitReportAlert = (username, reportComment) => {
    //console.log(username);
    this.props.handleReportAlert(username, reportComment);
    this.setState({ report: false });
  };
  handleUsefulChange = (value) => {
    let alert = this.state.alert;
    alert.is_useful = value;
    this.setState({ alert: alert });
  };

  handleSaveChanges = () => {
    let actions = [];
    this.state.alert.actions.map((action, index) => {
      //console.log(action);
      actions.push({
        new_attachments: action.newAttachments,
        comment_type: action.comment_type,
        comment: action.comment,
        attachments: action.attachments,
        id: action.id,
        removeAttachmentID: action.removeAttachmentID,
      });
    });
    let alert = this.state.alert;
    let dataPackage = {
      alert_type: alert.alert_type,
      alerted_object_id: alert.alerted_object_id,
      rational: alert.rational,
      description: alert.description,
      is_useful: alert.is_useful === "Yes",
      due_date: alert.due_date,
      owners: alert.owners,
      risk_id: alert.risk_id,
      actions: actions,
    };
    //console.log(dataPackage);
    this.props.handleUpdateAlert({ data: dataPackage });
  };

  handleAddAction = () => {
    let alert = this.state.alert;
    alert.actions.push({
      comment_type: "Select Form",
      comment: "",
      is_new: true,
      attachments: [],
    });
    this.setState({ alert: alert });
  };
  handleDeleteAction = (index) => {
    let alert = this.state.alert;
    alert.actions.splice(index, 1);
    this.setState({ alert: alert });
  };
  handleSelectAction = (index, value) => {
    if (value !== undefined) {
      let alert = this.state.alert;
      alert.actions[index].comment_type = value;
      this.setState({ alert: alert });
    }
  };
  onChangeTextField = (index, value) => {
    let alert = this.state.alert;
    alert.actions[index].comment = value;
    this.setState({ alert: alert });
  };

  handleCreateTask = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({ createTask: true, transfer: false, report: false });
  };
  handleTransferAlert = () => {
    this.setState({ transfer: true, report: false, createTask: false });
  };
  handleReportAlert = () => {
    this.setState({ report: true, createTask: false, transfer: false });
  };
  handleViewTask = (content) => {
    //console.log(content);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({
      report: false,
      createTask: false,
      transfer: false,
      viewTask: { display: true, content: content },
    });
  };
  handleRationalChange = (value) => {
    let alert = this.state.alert;
    alert.rational = value;
    this.setState({ alert: alert });
  };
  handleAlertComplete = () => {
    if (this.state.alert.rational === "") {
      this.setState({
        warning: {
          display: true,
          title: "Mark as completed failed",
          content: "Rational is required to mark this alert as completed",
          confirmFunction: () => {
            this.setState({
              warning: {
                display: false,
              },
            });
          },
        },
      });
    } else {
      this.setState({
        warning: {
          display: true,
          title: "Mark as completed",
          content:
            "Marking this alert as complete will close this alert, are you sure you want to close this alert?",
          confirmFunction: () => {
            this.props.handleCompleteAlert();
          },
        },
      });
    }
  };
}

export { AbcDemoAlertManagement };
