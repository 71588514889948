import React from "react";
import {
  mapping,
  LineChart,
  FormatDate,
  alertManagementStyle,
} from "@aim-mf/styleguide";

class AlertDataChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  calculateColor(value) {
    value = parseInt(value);
    if (this.props.alertType === "Key Risk Indicator") {
      if (this.props.object.safe_zone === "low") {
        if (value < this.props.object.lower_threshold || value === 0) {
          return mapping["Color_Basic_Success"];
        }
        if (
          value !== 0 &&
          value >= this.props.object.lower_threshold &&
          value <= this.props.object.upper_threshold
        )
          return mapping["Color_Basic_Warning"];
        if (value > this.props.object.upper_threshold)
          return mapping["Color_Basic_Danger"];
      } else {
        if (value < this.props.object.lower_threshold)
          return mapping["Color_Basic_Danger"];
        if (
          value >= this.props.object.lower_threshold &&
          value <= this.props.object.upper_threshold
        )
          return mapping["Color_Basic_Warning"];
        if (value > this.props.object.upper_threshold)
          return mapping["Color_Basic_Success"];
      }
    }

    return "#A2F8F7";
  }

  formatChartData(values) {
    let result = [];
    values.map((value) => {
      let color = this.calculateColor(value.value);

      let item = {};
      item["axisName"] = FormatDate(value.period, "DD/MM/YYYY");
      item["value"] = parseInt(value.value);
      item["color"] = color;
      result.push(item);
    });
    return result;
  }

  render() {
    return (
      <div style={alertManagementStyle.cardWrapper}>
        <div style={alertManagementStyle.mainHeader}>{this.props.title}</div>
        <div style={alertManagementStyle.chartWrapper}>
          {(!this.props.value || this.props.value.length === 0) && (
            <div style={alertManagementStyle.NoValueStyle}>
              No records available
            </div>
          )}
          {this.props.value && this.props.value.length !== 0 && (
            <LineChart
              max={
                Math.max(
                  ...this.props.value.map((value) => parseInt(value.value))
                ) + 5
              }
              height={"15.3125rem"}
              width={"38.75rem"}
              data={this.formatChartData(this.props.value)}
              onBarClick={() => {
                // console.log("on bar click");
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export { AlertDataChart };
