import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { FormatDate, alertManagementStyle } from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";
import { HeaderRender } from "../components/summary/HeaderRender";
import { fakeData } from "./fakeData";

class AbcDemoSummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: [{ field: "create_datetime", dir: "asc", data: this.props.data }],
    };
  }

  render() {
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    let chartData = JSON.parse(
      JSON.stringify(
        fakeData.filter((data) => {
          return data.risk_name === this.props.risk.name;
        })
      )
    );

    return (
      <div style={alertManagementStyle.tableWrapper}>
        <Grid
          style={alertManagementStyle.gridStyle}
          {...this.state.dataState}
          data={chartData}
          sortable
          cellRender={defaultRowCell}
          sort={this.state.sort}
          rowRender={this.rowRender}
          onSortChange={(e) => {
            var sortConfig = e.sort[0];
            if (!sortConfig) {
              this.setState({
                sort: [{ data: chartData }],
              });
            } else if (sortConfig.field === "formatDateTime") {
              sortConfig = { ...sortConfig, data: chartData };
              this.setState({
                sort: [sortConfig],
              });
            }
          }}
        >
          <Column
            field="alert_number"
            title="ID"
            width={(100 / 16) * rem + "px"}
            headerCell={HeaderRender}
            cell={(props) => {
              return (
                <td className="table-risk-id">
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <div
                    className="table-risk-id div_hover"
                    onClick={() => {
                      if (props.dataItem.id) {
                        navigateToUrl(
                          window.location.origin +
                            "/alert-management/" +
                            props.dataItem.id +
                            "/"
                        );
                        this.props.reloadAll();
                      }
                    }}
                  >
                    {props.dataItem["parentRisk_id"] ? "⤷" : " "}
                    {props.dataItem[props.field]}
                  </div>
                </td>
              );
            }}
          />
          <Column
            field="name"
            title={
              this.props.risk.name === "Travel and Entertainment"
                ? "Employee Name"
                : "Vendor Name"
            }
            headerCell={HeaderRender}
            width={(120 / 16) * rem + "px"}
          />
          <Column
            field="description"
            title="Description"
            headerCell={HeaderRender}
          />
          <Column
            field="status"
            title="Status"
            headerCell={HeaderRender}
            width={(120 / 16) * rem + "px"}
          />
          <Column
            field="created_date"
            title="Date Created"
            width={(140 / 16) * rem + "px"}
            headerCell={HeaderRender}
          />
          <Column
            field="owners"
            title="Alert Owner"
            width={(120 / 16) * rem + "px"}
            headerCell={HeaderRender}
            cell={(props) => {
              if (props.dataItem[props.field]) {
                return (
                  <td>
                    {props.dataItem[props.field].map((owner) => {
                      return (
                        <div>{owner}</div>
                        // <Pill
                        //   pillStyle={{
                        //     display: "inline-flex",
                        //     marginRight: "0.3rem",
                        //   }}
                        //   content={owner}
                        //   color={"rgba(124,199,255,0.2)"}
                        // />
                      );
                    })}
                  </td>
                );
              } else {
                return <td />;
              }
            }}
          />
        </Grid>
      </div>
    );
  }
}

const defaultRowCell = (tdElement, props) => {
  return (
    <td
      style={{
        padding: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "12px",
      }}
    >
      {props.dataItem[props.field]}
    </td>
  );
};

export { AbcDemoSummaryTable };
