import React from "react";
import { mapping, IconSVG } from "@aim-mf/styleguide";
import { SideBar } from "./sideBar";
import { AlertSummaryDetail } from "./AlertSummaryDetail";
import { navigateToUrl } from "single-spa";

class AlertSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{ display: "inline-flex", minHeight: "calc(100vh - 3.75rem)" }}
      >
        <SideBar
          menuHeader={"Alerts \n\r" + this.props.alertType}
          parentData={this.props.risk}
          parentNameKey={"name"}
          dataList={this.props.typeObjectList}
          dataNameKey={"description"}
          focusedID={this.props.alertedObjectId}
          handleClickParent={() => {
            if (this.props.risk.name === "Back to Dashboard") {
              navigateToUrl(window.location.origin + "/dashboard/");
            } else {
              navigateToUrl(
                window.location.origin +
                  "/risk-edit/" +
                  this.props.risk.id +
                  "/"
              );
            }
          }}
          handleDataItemClick={this.props.reloadAll}
        />
        <AlertSummaryDetail
          abc_demo={this.props.abc_demo}
          risk={this.props.risk}
          riskList={this.props.riskList}
          alertType={this.props.alertType}
          KRIobject={
            this.props.typeObjectList.filter((object) => {
              if (object.source_id === undefined || object.source_id === null) {
                return object.id === this.props.alertedObjectId;
              }
              return object.source_id === this.props.alertedObjectId;
            })[0]
          }
          alertList={this.props.alertList}
          reloadAll={this.props.reloadAll}
        />
      </div>
    );
  }
}

export { AlertSummary };
