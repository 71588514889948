import React from "react";
import { mapping, FormatDate, alertManagementStyle } from "@aim-mf/styleguide";
import {
  fakeAlertVendorDetailContent,
  fakeAlertVendorDetailHeader,
} from "./fakeData";

class AlertVendorDetailThirdPartyPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  render() {
    if (!this.props.alert) {
      return <div />;
    }
    const { header, content, name } = this.props;
    return (
      <div>
        <div style={alertManagementStyle.taskTitleStyle}>Alert Detail</div>

        <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
          <div>
            <div style={alertManagementStyle.sectionTitleStyle}>{name}</div>
          </div>

          {/*line one*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>{header.id}</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.id}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.contact}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.contact}
              </div>
            </div>
            <div style={{ width: "22.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.address}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.address}
              </div>
            </div>
          </div>

          {/*line two*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.created_date}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.created_date}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>{header.email}</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.email}
              </div>
            </div>
            <div style={{ width: "9.375rem", marginRight: "3.75rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.country}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.country}
              </div>
            </div>
            <div style={{ width: "9.375rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.postal}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.postal}
              </div>
            </div>
          </div>

          {/*line three*/}
          <div style={{ display: "inline-flex", paddingBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.approved_date}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.approved_date}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>{header.uen}</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.uen}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.telephone}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.telephone}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { AlertVendorDetailThirdPartyPayment };
