import React from "react";
import { alertManagementStyle } from "@aim-mf/styleguide";

class VendorScore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.alertData.length === 0) {
      return <div />;
    }
    let dataList = this.formatChartData(this.props.alertData);
    return (
      <div>
        <VendorScoreHeader
          dataList={dataList}
          width={this.props.width}
          additionalStyle={this.props.additionalStyle}
        />
        <VendorScoreContent
          dataList={dataList}
          width={this.props.width}
          additionalStyle={this.props.additionalStyle}
        />
      </div>
    );
  }

  formatChartData(values) {
    let result = [];
    let ObjectKeys = this.props.BoxPlotHeader;
    let item = {};
    ObjectKeys.map((key) => {
      item.title = key;
      let alertVendor = values.filter((data) => {
        return data.Vendor === this.props.alertDetail.alert_data_index;
      })[0];
      if (alertVendor === undefined) {
        alertVendor = values[0];
      }
      key = key.replace(/\n/g, "");
      if (key === ObjectKeys[0].replace(/\n/g, ""))
        item.value = alertVendor.Vendor;
      if (key === ObjectKeys[1].replace(/\n/g, ""))
        item.value = alertVendor.score_for_no_of_po;
      if (key === ObjectKeys[2].replace(/\n/g, ""))
        item.value = alertVendor.score_for_no_of_po_pertime;
      if (key === ObjectKeys[3].replace(/\n/g, ""))
        item.value = alertVendor.score_for_no_of_po_per_time_std;
      if (key === ObjectKeys[4].replace(/\n/g, ""))
        item.value = alertVendor.score_for_percent_created_nwh;
      if (key === ObjectKeys[5].replace(/\n/g, ""))
        item.value = alertVendor.score_for_percent_approved_nwh;
      if (key === ObjectKeys[6].replace(/\n/g, ""))
        item.value = alertVendor.score_for_no_of_po_pertime;
      if (key === ObjectKeys[7].replace(/\n/g, ""))
        item.value = alertVendor.score_for_mad_cluster;
      if (key === ObjectKeys[8].replace(/\n/g, ""))
        item.value = alertVendor.score_for_mad_vendor;
      if (key === ObjectKeys[9].replace(/\n/g, ""))
        item.value = alertVendor.score_for_no_of_createdby;
      if (key === ObjectKeys[10].replace(/\n/g, ""))
        item.value = alertVendor.score_for_no_of_approvedby;
      if (key === ObjectKeys[11].replace(/\n/g, ""))
        item.value = alertVendor.score_for_no_of_similar_add;
      result.push(JSON.parse(JSON.stringify(item)));
    });
    return result;
  }
}

const VendorScoreHeader = (props) => {
  return (
    <div
      style={{
        ...alertManagementStyle.boxPlotStyle,
        height: props.additionalStyle
          ? props.additionalStyle.lineHeight
          : "3.125rem",
      }}
    >
      {props.dataList.map((data) => {
        return (
          <pre
            style={{
              ...alertManagementStyle.boxPlotTitleFont,
              width: data.title === "Vendor" ? "3.125rem" : props.width,
              height: props.additionalStyle
                ? props.additionalStyle.textHeight
                : "2.5rem",
              margin: 0,
              whiteSpace: "normal",
              fontSize: props.additionalStyle
                ? props.additionalStyle.fontSize
                : alertManagementStyle.boxPlotTitleFont.fontSize,
            }}
          >
            {data.title}
          </pre>
        );
      })}
    </div>
  );
};

const VendorScoreContent = (props) => {
  let maxData = Math.max.apply(
    null,
    props.dataList.map((data) => {
      if (data.title === "Vendor") return 1;
      return data.value;
    })
  );
  return (
    <div
      style={{
        ...alertManagementStyle.boxPlotStyle,
        height: "1.25rem",
        padding: 0,
        marginBottom: "0.4375rem",
        backgroundColor: "#00000000",
      }}
    >
      {props.dataList.map((data) => {
        let yellowDegree =
          "0" + Math.round((1 - data.value / maxData) * 255).toString(16);
        yellowDegree = yellowDegree.slice(yellowDegree.length - 2);
        let dataColor = "#ff" + yellowDegree + "0050";
        return (
          <pre
            style={{
              ...alertManagementStyle.boxPlotTitleFont,
              width: data.title === "Vendor" ? "3.75rem" : props.width,
              height: "1.25rem",
              margin: 0,
              backgroundColor:
                data.title === "Vendor" ? "#00000050" : dataColor,
              paddingTop: "0.4375rem",
              fontSize: props.additionalStyle
                ? props.additionalStyle.valueFontSize
                : alertManagementStyle.boxPlotTitleFont.fontSize,
            }}
          >
            {data.value}
          </pre>
        );
      })}
    </div>
  );
};

export { VendorScore };
