import React from "react";
import { FormatDate, alertManagementStyle } from "@aim-mf/styleguide";
import { AlertDataChart } from "./components/summary/AlertDataChart";
import { AlertsTable } from "./components/summary/AlertsTable";
import { AlertSummaryDescription } from "./components/summary/AlertSummaryDescription";
import { IAEAlertsTable } from "./components/summary/IAEAlertsTable";
import { AbcDemoSummaryTable } from "./ABCDemoPage/abcDemoSummaryTable";

class AlertSummaryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let chartValue = 0;
    let dataObject = {};
    let title = "";
    if (
      this.props.alertType === "Key Risk Indicator" ||
      this.props.alertType === "vendorAnalysis"
    ) {
      title = "Key Risk Indicator";
      chartValue = this.props.KRIobject.value;
      dataObject = this.props.KRIobject;
    } else if (this.props.alertType === "Integrated Assurance Exceptions") {
      title = "Integrated Assurance Exceptions";
      chartValue = sortIAEAlertData(this.props.alertList);
      dataObject = {
        description:
          "Risk controls where the three lines of defence are not aligned",
      };
    }

    return (
      <div>
        <div style={alertManagementStyle.detailWrapper}>
          <div style={{ marginRight: "2.5rem" }}>
            <AlertSummaryDescription
              descriprtion={dataObject.description}
              title={title}
            />
          </div>
          <div>
            <AlertDataChart
              object={dataObject}
              value={chartValue}
              title={title}
              alertType={this.props.alertType}
            />
          </div>
        </div>
        {this.props.abc_demo && (
          <AbcDemoSummaryTable
            riskList={this.props.riskList}
            risk={this.props.risk}
            reloadAll={this.props.reloadAll}
          />
        )}
        {!this.props.abc_demo &&
          this.props.alertType === "Integrated Assurance Exceptions" && (
            <IAEAlertsTable
              riskList={this.props.riskList}
              data={this.props.alertList}
              reloadAll={this.props.reloadAll}
            />
          )}
        {!this.props.abc_demo &&
          this.props.alertType !== "Integrated Assurance Exceptions" &&
          this.props.alertType !== "ABC Demo" && (
            <AlertsTable
              data={this.props.alertList}
              reloadAll={this.props.reloadAll}
            />
          )}
        <div style={{ height: "1.25rem" }} />
      </div>
    );
  }
}

const sortIAEAlertData = (alertList) => {
  alertList.map((alert) => {
    alert.alert_date = FormatDate(alert.create_datetime, "YYYY-MM");
  });

  // count alert with same alert date
  const result = [
    ...alertList
      .reduce((mp, o) => {
        if (!mp.has(o.alert_date))
          mp.set(o.alert_date, { period: o.alert_date, value: 0 });
        mp.get(o.alert_date).value++;
        return mp;
      }, new Map())
      .values(),
  ];

  // return chart data result
  return result;
};

export { AlertSummaryDetail };
