import React from "react";
import { mapping, FormatDate, alertManagementStyle } from "@aim-mf/styleguide";

class AlertVendorDetailTE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createTask: false,
      Confirmation: { display: false },
    };
  }

  render() {
    if (!this.props.alert) {
      return <div />;
    }
    const { header, content } = this.props;
    return (
      <div>
        <div style={alertManagementStyle.taskTitleStyle}>Alert Detail</div>

        <div style={alertManagementStyle.detailTopDetailStyleWrapper}>
          <div>
            <div style={alertManagementStyle.sectionTitleStyle}>
              {this.props.alert.name}
            </div>
          </div>

          {/*line one*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.employee_id}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.employee_id}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.department}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.department}
              </div>
            </div>
            <div style={{ width: "22.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.address}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.address}
              </div>
            </div>
          </div>

          {/*line two*/}
          <div style={{ display: "inline-flex", marginBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "5.5625rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.hired_date}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.hired_date}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>{header.email}</div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.email}
              </div>
            </div>
            <div style={{ width: "9.375rem", marginRight: "3.75rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.country}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.country}
              </div>
            </div>
            <div style={{ width: "9.375rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.postal}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.postal}
              </div>
            </div>
          </div>

          {/*line three*/}
          <div style={{ display: "inline-flex", paddingBottom: "1.875rem" }}>
            <div style={{ width: "9.375rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.termination_date}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.termination_date}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.cost_center}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.cost_center}
              </div>
            </div>
            <div style={{ width: "22.5rem", marginRight: "2.5rem" }}>
              <div style={mapping["forms/label/1-default"]}>
                {header.telephone}
              </div>
              <div style={alertManagementStyle.detailTopContentStyle}>
                {content.telephone}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { AlertVendorDetailTE };
