import React from "react";
import { mapping, HorizontalBarChart } from "@aim-mf/styleguide";

class RoutineAlertHorizontalBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedCluster: [],
      sortedData: [],
    };
  }

  componentWillMount() {
    //   let sortedData = this.sortDataByCluster(this.props.alertData)
    //   this.setState({sortedData:sortedData})
    //   sortedData.map((value) => {
    //     value.map((vendor) => {
    //       if (vendor.Vendor === this.props.alertDetail.alert_data_index) {
    //         this.props.onBubbleClick(value)
    //       }
    //     });
    // })
  }

  sortDataByDocumentType(data) {
    let result = {
      Payments: [],
      "Goods Receipts": [],
      "Purchase Orders": [],
      Invoices: [],
    };
    data.map((transaction) => {
      result[transaction.group].push(transaction);
    });

    return result;
  }

  formatChartData(values) {
    let result = {};
    // sort data into smaller list according to cluster number
    let sortedData = this.sortDataByDocumentType(values);
    for (let key in sortedData) {
      if (key === "Payments") {
        result[key] = sortedData[key].reduce((acc, transaction) => {
          return acc + transaction.payment_amount;
        }, 0);
      } else if (key === "Goods Receipts") {
        result[key] = sortedData[key].reduce((acc, transaction) => {
          return acc + transaction.received_amount;
        }, 0);
      } else if (key === "Invoices") {
        result[key] = sortedData[key].reduce((acc, transaction) => {
          return acc + transaction.amount;
        }, 0);
      } else if (key === "Purchase Orders") {
        result[key] = sortedData[key].reduce((acc, transaction) => {
          return acc + transaction.amount_gross;
        }, 0);
      }
    }
    let data = [];
    for (let key in result) {
      let item = {};
      item.category = key;
      item.value = result[key].toFixed(2);
      item.color = "#A2F8F7C0";
      data.push(item);
    }

    return data;
  }

  render() {
    if (this.props.data.length === 0) {
      return <div />;
    }
    return (
      <HorizontalBarChart
        XAxisName={"Amount"}
        YAxisName={"Document Type"}
        gap={0.8}
        height={this.props.height}
        width={this.props.width}
        data={this.formatChartData(this.props.data)}
        onBubbleClick={(event) => {
          let dataIndex = event.series.data.indexOf(event.dataItem);
          this.props.onBubbleClick(this.state.sortedData[dataIndex]);
        }}
      />
    );
  }
}

export { RoutineAlertHorizontalBarChart };
